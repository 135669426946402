import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import ReportHead from './ReportHead';
import ReportGraph from './ReportGraph';
import axis from 'axis.js';
import ColumnModal from './ModalAddColumnForm';
import ReportColumns from './ReportColumns';
import { changeColumn, changeFirstColumnFilter, changeSecColumnFilter, getData } from '../../actions/reportAction';
import Utils from '../../helpers/Utils';
import { AllColumns, DefaultColumns, HideColumns } from '../../constants/CoreConstants';
import If from 'if-else-react'
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import NewLoader from '../common/NewLoader';
import './business-report.css';
import './reportgraph.css';
import './report-table.css';


const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
});

const EachAvatarHeader = (props) => {
    return (
        <TableCell>UserName</TableCell>
    )
};

const EachHeader = (props) => {
    return (
        <TableCell>{props.title}</TableCell>
    )
};

const UserAvatar = (props) => {
    return (
        <TableCell>
            <If condition={props.data != undefined}>
                <span className="table__user__short">{Utils.fullnameInitialFormatter(props.data.full_name)}</span>
                <span className="table__user__title">{props.data.full_name}</span>
                </If>
        </TableCell>
    )
};

const EachTableTD = (props) => {
    return (
        <TableCell align={"center"}>
            {props.data ? <a href="">
                        {props.data}
                    </a> : 0}
        </TableCell>
    )
};

const EachTableTR = (props) => {
    return (
        <TableRow>
            {props.children}
        </TableRow>
    )
};


const mapStateToProps = state => {
    return {
        reportCurrentUser: state.reportReducer.reportCurrentUser,
        reportOthersUser: state.reportReducer.reportOthersUser,
        reportUserPrevious: state.reportReducer.reportUserPrevious,
        subUsers: state.reportReducer.subUsers,
        loading: state.reportReducer.loading,
        defaultColumns: state.reportReducer.defaultColumns,
        allColumns: state.reportReducer.allColumns,
        currentSecColumnFilterID: state.reportReducer.currentSecColumnFilterID,
        currentFirstColumnFilterID: state.reportReducer.currentFirstColumnFilterID,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getData: (data) => dispatch(getData(data)),
        changeFirstColumnFilter: (dateType) => dispatch(changeFirstColumnFilter(dateType)),
        changeSecColumnFilter: (dateType) => dispatch(changeSecColumnFilter(dateType)),
        changeColumn: (dateType) => dispatch(changeColumn(dateType)),
    };
}


const ReportApp = (props) => {
    
    const [showModal,setShowModal] = useState(false);
    const classes = useStyles();

    useEffect(() => {

        document.title = `Business Report | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('report');
        }

        let filterParams = {
            id: 'today',
            title: "Today"
        };

        let previousFilter = localStorage.getItem("date_filter");

        if (!axis.isNull(previousFilter)) {
            filterParams = JSON.parse(previousFilter);
        }

        props.getData(filterParams);
    },[])

    const changeBoxWise = (data) => {
        if (!axis.isUndefined(DefaultColumns[data])) {
            if (props.currentSecColumnFilterID === data) {
                props.changeSecColumnFilter({
                    id: "null",
                    title: "Nothing"
                });
                props.changeFirstColumnFilter({
                    id: data,
                    title: DefaultColumns[data]
                });
            } else if (props.currentSecColumnFilterID == "null" && props.currentFirstColumnFilterID !== data) {
                props.changeSecColumnFilter({
                    id: data,
                    title: DefaultColumns[data]
                });
            } else if (props.currentSecColumnFilterID !== data) {
                props.changeFirstColumnFilter({
                    id: data,
                    title: DefaultColumns[data]
                });
            }
        } else if (!axis.isUndefined(AllColumns[data])) {
            if (props.currentSecColumnFilterID === data) {
                props.changeSecColumnFilter({
                    id: "null", title: "Nothing"
                });
            }
            props.changeFirstColumnFilter({
                id: data,
                title: AllColumns[data]
            });
        }
    };

    const calculateGraphData = ($allReports) => {
        let calculate = {};
        $allReports.map((data, index) => {
            for (let i in data) {
                if (axis.isUndefined(HideColumns[i])) {
                    if (!axis.isUndefined(calculate[i])) {
                        calculate[i] = {
                            title: AllColumns[i],
                            val: calculate[i].val + parseInt(data[i]),
                            graphData: calculate[i].graphData.concat([{
                                val: parseInt(data[i]),
                                date: data['local_created_at'].date_time
                            }])
                        }
                    } else {
                        calculate[i] = {
                            title: i,
                            val: parseInt(data[i]),
                            graphData: [{
                                val: parseInt(data[i]),
                                date: data['local_created_at'].date_time
                            }]
                        }
                    }
                }
            }
        });

        return calculate;
    };


    const submitNewColumn = (addColumns) => {
        props.changeColumn({
            addColumns
        });
    };

    const renderColumns = () => {
        let columns = [];

        if (props.reportCurrentUser.length > 0) {
            let calValue = calculateGraphData(props.reportCurrentUser);
            let calValuePrev = calculateGraphData(props.reportUserPrevious);
            for (let i in calValue) {
                if (!axis.isUndefined(props.defaultColumns[i])) {
                    columns.push(<ReportColumns
                        changeBoxWise={changeBoxWise}
                        item={i}
                        key={i}
                        graphData={calValue[i].graphData}
                        previousData={calValuePrev[i]}
                        title={calValue[i].title}
                        data={calValue[i].val}/>);
                }
            }
        }

        return columns;
    }

    const renderTableHeader = () => {
        let tableHeader = [];
        
        if ((props.reportOthersUser && props.reportOthersUser.length > 0 )|| (props.subUsers && props.subUsers.length > 0)) {
            tableHeader.push(<EachAvatarHeader/>);

            for (let i in props.defaultColumns) {
                tableHeader.push(<EachHeader key={i} title={props.defaultColumns[i]}/>);
            }
        }

        return tableHeader;
    }

    const renderUserData = () => {
        let UserData = null;
        if ((props.reportOthersUser && props.reportOthersUser.length > 0 )|| (props.subUsers && props.subUsers.length > 0)) {
            let key = 1;
            if (props.reportOthersUser.length > 0) {
                UserData = props.reportOthersUser.map((data, index) => {
                    let allTD = [];
                    allTD.push(<UserAvatar data={data} key={key++}/>);

                    if (data.reports.length > 0) {
                        let singleData = data.reports[0];
                        for (let i in singleData) {
                            if (axis.isUndefined(HideColumns[i]) && !axis.isUndefined(props.defaultColumns[i])) {
                                allTD.push(<EachTableTD key={i} data={singleData[i]}/>)
                            }
                        }
                    } else {
                        for (let i in props.defaultColumns) {
                            allTD.push(<EachTableTD key={i} data={0}/>)
                        }
                    }
                    return (
                        <EachTableTR>
                            {allTD}
                        </EachTableTR>
                    )
                });
            } else {
                UserData = props.subUsers.map((data, index) => {
                    let allTD = [];
                    allTD.push(<UserAvatar data={data}/>);
                    for (let i in props.defaultColumns) {
                        allTD.push(<EachTableTD key={i} data={0}/>)
                    }
                    return (
                        <EachTableTR>
                            {allTD}
                        </EachTableTR>
                    )
                });
            }
        }

        return UserData;
    }

    return (
        <div className="business__report__wr min-height-500">
            <If condition={props.loading}>
                <div className="row">
                    <div className="text-center w-100 m--margin-100 parent-loader-div">
                        {/* <Loading/> */}
                        <NewLoader loading={props.loading} onlyLoader={true} size={34} message={""}/>
                    </div>
                </div>
            </If>
            <If condition={!props.loading}>
                <ReportHead/>
                <div className="business__chart__wr">
                    <ReportGraph/>
                    <div className="chart__count__wr">
                        {renderColumns()}
                        <div className="chart__count__single add__chart__count__single" onClick={() =>setShowModal(true)}>  
                            <div className="add__chart__count__container">
                                <span className={"up__arrow up__arrow__blue"}>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 4.93408C10 5.38884 9.96 5.75643 9.49917 5.75643H5.83333V9.37393C5.83333 9.82787 5.46083 9.86816 5 9.86816C4.53917 9.86816 4.16667 9.82787 4.16667 9.37393V5.75643H0.500833C0.0408332 5.75643 0 5.38884 0 4.93408C0 4.47932 0.0408332 4.11173 0.500833 4.11173H4.16667V0.49423C4.16667 0.0394725 4.53917 0 5 0C5.46083 0 5.83333 0.0394725 5.83333 0.49423V4.11173H9.49917C9.96 4.11173 10 4.47932 10 4.93408Z" fill="white"/>
                                    </svg>
                                </span>     
                                <h4 className="add__column__title">Add Columns</h4>
                            </div>
                        </div> 
                        <ColumnModal
                            allColumns={props.allColumns}
                            defaultColumns={props.defaultColumns}
                            title={"Change Column"}
                            isOpen={showModal}
                            closeModal={() => setShowModal(false)}
                            addData={submitNewColumn}
                        />                            
                    </div>
                </div>
                

                <If condition={(props.reportOthersUser && props.reportOthersUser.length > 0 )|| (props.subUsers && props.subUsers.length > 0)}>
                    <div className="chart__table__wr">
                        <TableContainer className={classes.container +" awesome__scroll_bar2"}>
                            <Table stickyHeader aria-label="sticky table" className={"chart__table "+classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {renderTableHeader()}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="chart__table__body">
                                    {renderUserData()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </If>
            </If>
        </div>
    );
}

const ReportIndex = connect(mapStateToProps, mapDispatchToProps)(ReportApp);

export default ReportIndex;
