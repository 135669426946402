import ReactEcharts from 'echarts-for-react';
import moment from "moment";
import Utils from '../../../helpers/Utils';

const arrowUP = (<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M11 16H13V11.8L14.6 13.4L16 12L12 8L8 12L9.4 13.4L11 11.8ZM12 22Q9.925 22 8.1 21.212Q6.275 20.425 4.925 19.075Q3.575 17.725 2.788 15.9Q2 14.075 2 12Q2 9.925 2.788 8.1Q3.575 6.275 4.925 4.925Q6.275 3.575 8.1 2.787Q9.925 2 12 2Q14.075 2 15.9 2.787Q17.725 3.575 19.075 4.925Q20.425 6.275 21.212 8.1Q22 9.925 22 12Q22 14.075 21.212 15.9Q20.425 17.725 19.075 19.075Q17.725 20.425 15.9 21.212Q14.075 22 12 22ZM12 20Q15.325 20 17.663 17.663Q20 15.325 20 12Q20 8.675 17.663 6.337Q15.325 4 12 4Q8.675 4 6.338 6.337Q4 8.675 4 12Q4 15.325 6.338 17.663Q8.675 20 12 20ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Z" fill="#6fcf97"/></svg>)
const arrowRight = (<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 16 16 12 12 8 10.6 9.4 12.2 11H8V13H12.2L10.6 14.6ZM12 22Q9.925 22 8.1 21.212Q6.275 20.425 4.925 19.075Q3.575 17.725 2.788 15.9Q2 14.075 2 12Q2 9.925 2.788 8.1Q3.575 6.275 4.925 4.925Q6.275 3.575 8.1 2.787Q9.925 2 12 2Q14.075 2 15.9 2.787Q17.725 3.575 19.075 4.925Q20.425 6.275 21.212 8.1Q22 9.925 22 12Q22 14.075 21.212 15.9Q20.425 17.725 19.075 19.075Q17.725 20.425 15.9 21.212Q14.075 22 12 22ZM12 20Q15.325 20 17.663 17.663Q20 15.325 20 12Q20 8.675 17.663 6.337Q15.325 4 12 4Q8.675 4 6.338 6.337Q4 8.675 4 12Q4 15.325 6.338 17.663Q8.675 20 12 20ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Z" fill="#4d7cfe"/></svg>)
const arrowDown = (<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 16 16 12 14.6 10.6 13 12.2V8H11V12.2L9.4 10.6L8 12ZM12 22Q9.925 22 8.1 21.212Q6.275 20.425 4.925 19.075Q3.575 17.725 2.788 15.9Q2 14.075 2 12Q2 9.925 2.788 8.1Q3.575 6.275 4.925 4.925Q6.275 3.575 8.1 2.787Q9.925 2 12 2Q14.075 2 15.9 2.787Q17.725 3.575 19.075 4.925Q20.425 6.275 21.212 8.1Q22 9.925 22 12Q22 14.075 21.212 15.9Q20.425 17.725 19.075 19.075Q17.725 20.425 15.9 21.212Q14.075 22 12 22ZM12 20Q15.325 20 17.663 17.663Q20 15.325 20 12Q20 8.675 17.663 6.337Q15.325 4 12 4Q8.675 4 6.338 6.337Q4 8.675 4 12Q4 15.325 6.338 17.663Q8.675 20 12 20ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Z" fill="#ff5858"/></svg>)

const EachCardItem = ({item, key_data, currentData, countData, previousCountData}) => {

    const renderGraph = () => {
        let xAxisData = [];
        let yAxisData = [];        
        currentData.forEach((data, index) => {
            if(data[key_data] !== undefined){
                yAxisData.push(data[key_data]);
                xAxisData.push(data.created_at);
            }
        });

        let option = {
            xAxis: [
                {
                    data: xAxisData,
                    show:false,
                }, {
                    data: xAxisData,
                    show:false
                }
            ],
            yAxis:[
                {
                    show:false,
                    splitLine: {show: false}
                }, {
                    splitLine: {show: false},
                    show:false
                }
            ],
            grid: [{
                left:'0%',
                right:'0%',
                bottom: '5%'
            }],
            series: [{
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#114ea9' // color at 0% position
                    }, {
                        offset: 1, color: '#3bd1bf' // color at 100% position
                    }],
                    global: false // false by default
                },
                data: yAxisData,
                type: 'line',
                showSymbol: false,
            }]
        };
        return(
            <ReactEcharts  
                style={{height: heightIncrease ? '100px' : '27px', width: '100%'}}
                notMerge={true}
                lazyUpdate={true}
                theme={"light"}
                option={option} 
            />
        )
    }

    const isFloat = (n) => {
        return Number(n) === n && n % 1 !== 0;
    }

    let current_value = 0;
    if(countData['sum_'+key_data] !== undefined && countData['sum_'+key_data] != null){
        current_value = countData['sum_'+key_data]
        if(typeof current_value !== 'number'){
            if(current_value.includes(".")){
                current_value = parseFloat(current_value)
            }
            else{
                current_value = parseInt(current_value)
            }
        }
    }

    let previous_value = 0;
    if(previousCountData['sum_'+key_data] !== undefined && previousCountData['sum_'+key_data] != null){
        previous_value = previousCountData['sum_'+key_data]
        if(typeof previous_value !== 'number'){
            if(previous_value.includes(".")){
                previous_value = parseFloat(previous_value)
            }
            else{
                previous_value = parseInt(previous_value)
            }
        }
    }

    let isIncrease = false;
    let percentage = null;

    if(previous_value < current_value){
        isIncrease = true;
        percentage = ((current_value - previous_value) / current_value ) * 100;
    } else {
        percentage = ((previous_value - current_value) / previous_value ) * 100;
    }

    if(isNaN(percentage)) {
        percentage = 0;
    }

    if(isFloat(percentage)){
        percentage = percentage.toFixed(2);
    }

    let icon = isIncrease ? arrowUP : ((parseInt(previous_value) === parseInt(current_value)) ? arrowRight : arrowDown);
    let heightIncrease = currentData.length > 1;

    if(key_data === 'deal_value'){
        current_value = Utils.calculateCash(current_value);
    }

    return(
        <div className="business__report__card__each__item__container" 
        >
            <div className='business__report__card__each__item__title'>{item}</div>
            <div className='business__report__card__each__item__value'>{current_value}</div>
            <div className='business__report__card__each__item__icon'>{icon}</div>
            <div className='business__report__card__each__item__percentage'>({percentage}%) <small>vs {previous_value}</small></div>
            {heightIncrease &&
            <div>
                {renderGraph()}
            </div>
            }
        </div>
    );
}
export default EachCardItem;