import React, { Component } from 'react';
import OutSideEvent from './OutSideEvent.js';
import {connect} from "react-redux";
import { changeFirstColumnFilter } from '../../actions/reportAction.js';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

function mapDispatchToProps(dispatch) {
    return {
        changeFirstColumnFilter: (dateType) => dispatch(changeFirstColumnFilter(dateType)),
    };
}


const mapStateToProps = state => {
    return {
        currentFirstColumnFilterTitle: state.reportReducer.currentFirstColumnFilterTitle,
        currentFirstColumnFilterID: state.reportReducer.currentFirstColumnFilterID,
        currentSecColumnFilterID: state.reportReducer.currentSecColumnFilterID,
        allColumns : state.reportReducer.defaultColumns
    };
};


class DateFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown : false
        };
        this.filterTypes = {
            new_leads : 'New Leads',
            calls :'Calls',
            emails :'Emails',
            texts :'Texts',
            notes :'Notes',
            task_completed :'Tasks Completed',
            appointments_set :'Appointments Set',
            appointments :'Appointments',
            leads_not_acted_on :'Leads not acted on',
            percentage_leads_not_acted_on :'% of leads not acted on',
            percentage_leads_not_responding :'% of leads responding'
        };
    }

    switchHandler = ()=>{
        this.setState({
            showDropdown : !this.state.showDropdown
        });
    };

    hideHandler = ()=>{
        this.setState({
            showDropdown : false
        });
    };

    switchType = (e)=>{
        this.props.handler(e.target.name)
    };

    selectType = ({id,title})=>{
        this.props.changeFirstColumnFilter({
            id,
            title
        });
        this.hideHandler();
    };


    render() {

        const allTypes = [];
        for(let i in this.props.allColumns){
            if(i !== this.props.currentSecColumnFilterID)
            allTypes.push(<MenuItem key={i} value={i} className={"repot_dropdown_item"}>{this.props.allColumns[i]}</MenuItem>);
        }

        return (
            <FormControl variant="outlined" margin="none" focused="false" className="business_select__wr">
                <Select
                    className="business_select select__dark__back select__dark__text"
                    labelId="demo-simple-select-outlined-label"
                    value={this.props.currentFirstColumnFilterID}
                    onChange={(e,value) => this.selectType({id:value.props.value,title:value.props.children})}
                    IconComponent = {KeyboardArrowDown}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                    }}
                >
                  {allTypes} 
                </Select>
            </FormControl>

        );
    }
}


const FirstFilterColumnWise = connect(mapStateToProps, mapDispatchToProps)(DateFilter);

export default FirstFilterColumnWise;