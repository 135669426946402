import React, { Component} from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createNewGoal, goalPipeline, goalStage, goalTeam, goalUser } from '../../api/goalApi';
import If from 'if-else-react';
import CoreConstants from '../../constants/CoreConstants';
import GlobalModal from '../globals/Modal/GlobalModal';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './goalModal.css';
import { Add, ArrowBack, ArrowForward, Block } from '@material-ui/icons';
import { iconList } from '../globals/IconList';
import Tooltip from '@material-ui/core/Tooltip';

const TYPE_COMPANY=1;
const TYPE_TEAM=2;
const TYPE_USER=3;

const GOAL_TYPE_KEY_ACTIVITY_ADDED=1;
const GOAL_TYPE_KEY_ACTIVITY_COMPLETED=2;
const GOAL_TYPE_KEY_DEALS_STARTED=3;
const GOAL_TYPE_KEY_DEALS_PROGRESSED=4;
const GOAL_TYPE_KEY_DEALS_WON=5;
const GOAL_TYPE_KEY_DEALS_LOST=6;

const TASK_CALL=1;
const TASK_MEETING=2;
const TASK_TASK=3;
const TASK_DEADLINE=4;
const TASK_EMAIL=5;
const TASK_FOLLOWUP=6;

const EXPECTED_OUTCOME_AMOUNT=1;
const EXPECTED_OUTCOME_VALUE=2;

const NO_SPECIFIC_PIPELINE=0;
const ALL_PIPELINE=-1;

const GOAL_INTERVAL_WEEKLY=1;
const GOAL_INTERVAL_MONTHLY=2;
const GOAL_INTERVAL_QUARTERLY=3;
const GOAL_INTERVAL_YEARLY=4;

const GOAL_DURATION_TYPE_THIS_MONTH=1;
const GOAL_DURATION_TYPE_NEXT_MONTH=2;
const GOAL_DURATION_TYPE_THIS_QUARTER=3;
const GOAL_DURATION_TYPE_NEXT_QUARTER=4;
const GOAL_DURATION_TYPE_THIS_YEAR=5;
const GOAL_DURATION_TYPE_NEXT_YEAR=6;
const GOAL_DURATION_TYPE_DATE_RANGE=7;
const GOAL_DURATION_TYPE_THIS_WEEK=8;
const GOAL_DURATION_TYPE_NEXT_WEEK=9;

const date = new Date();

class GoalModal extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    };

    getInitialState = () => {

        return {
            pipelines:[],
            stages: [],
            teams:[],
            userList : [],
            stepComplete: 0,
            stepActive: 1,
            // assigneeType: TYPE_COMPANY,
            assigneeType: "",
            assigneeTo: null,
            selectedUser: null,
            selectedTeam: 0,
            // continueButtonActive: true,
            continueButtonActive: false,
            userListShow: false,
            teamListShow: false,
            assigneeText: 'Everyone',
            assigneeIcon: 'fa fa-building',
            selectedGoalType : 0,
            selectedGoalValue : 0,
            goalTitle : 'Not Specified',
            goalTypeText : 'Not Specified',
            goalTitlePrevious : 'Not Specified',
            goalTypeIcon : 'fa fa-calendar',
            goalPipelineText : 'Not Specified',
            goalStageText : '',
            goalPipelineIcon : 'fa fa-tasks',
            goalExpectedOutComeText : 'Not Specified',
            goalExpectedOutComeIcon : 'fa fa-bullseye',
            goalIntervalText : 'Not Specified',
            goalIntervalIcon : 'fa fa-clock-o',
            goalDurationText : 'Not Specified',
            goalDurationIcon : 'fa fa-calendar',
            pipelineId : null,
            stageId : null,
            expectedOutcomeValue : '',
            expectedOutcomeType: EXPECTED_OUTCOME_AMOUNT,
            goalIntervalOption : 0,
            goalDurationOption : 0,
            editableDuration : false,
            firstDay : new Date(date.getFullYear(), date.getMonth(), 1),
            lastDay : new Date(date.getFullYear(), date.getMonth() + 1, 0),
            goalDurationStart :  moment().startOf('month').format('DD/MM/YYYY'),
            goalDurationEnd :  moment().endOf('month').format('DD/MM/YYYY'),
            goalTitleEditMode : false,
            goalTitleEdited : false,
            submitBtnText : 'Continue',
            submitForm : false,
            backBtnClassName : "btn bg-white border-1 border-radius-2",
            continueBtnIconClassName : "fa fa-arrow-right",
            errorMessage : ''
        };
    };

    resetBuilder = () => {
        this.setState(this.getInitialState);
    };

    componentDidMount() {};

    componentWillReceiveProps(nextProps) {
        if(nextProps.isOpen === true){
            this.resetBuilder();
            if(nextProps.activeCompany === true){
                this.setState({
                    assigneeType: TYPE_COMPANY
                });
                this.getPipeline(2, null);
            }else if(nextProps.activeUserId){
                this.getUser();
                this.setState({
                    assigneeType: TYPE_USER,
                    selectedUser : nextProps.activeUserId,
                    userListShow : true,
                    assigneeText: 'User',
                    assigneeIcon: 'fa fa-user',
                });
                this.getPipeline(1, nextProps.activeUserId);
            }else if(nextProps.activeTeamId){
                this.getTeam();
                this.setState({
                    assigneeType: TYPE_TEAM,
                    selectedTeam : nextProps.activeTeamId,
                    teamListShow : true,
                    assigneeText: 'Group',
                    assigneeIcon: 'fa fa-users',
                });
                this.getPipeline(2, null);
            }
        }
    }

    getPipeline = (from, userId=null) => {
        // Todo:
        // if possible then send userId/teamId in this function else
        // set data in this state and access from here
        if(parseInt(from) === 1){
            goalPipeline({from:from,userId:userId}).then(res => {
                this.setState({
                    pipelines: res.data.data.pipelines,
                })
            });
        }
        else if(parseInt(from) === 2){
            goalPipeline({from:from}).then(res => {
                this.setState({
                    pipelines: res.data.data.pipelines,
                })
            });
        }
    };

    getStage = (pipelineId) => {
        // Todo:
        // if possible then send userId/teamId in this function else
        // set data in this state and access from here
        goalStage({pipelineId:pipelineId}).then(res => {
            this.setState({
                stages: res.data.data.stages,
            })
        });
    };

    getTeam = () => {

        goalTeam({}).then(res => {
            this.setState({
                teams: res.data.data.teams,
            });
        });
    };

    getUser = () => {
        goalUser({}).then(res => {
            this.setState({
                userList: res.data.data.users,
            });
        });
    };

    pipelineList = () => {
        let pipelineList = this.state.pipelines.map(function (item, index) {
            return  <MenuItem key={index} value={item['id']} >
                {item['title']}
            </MenuItem>
        }.bind(this));
        return pipelineList;
    };

    stageList = () => {
        let stageList = this.state.stages.map(function (item, index) {
            return  <MenuItem key={index} value={item['id']} >
                {item['stage']}
            </MenuItem>

        }.bind(this));

        return stageList;
    };

    teamList = () => {
        let teamList = this.state.teams.map(function (item, index) {
            return  <MenuItem key={index} value={item['id']} >
                {item['name']}
            </MenuItem>

        }.bind(this));

        return teamList;
    };

    userList = () => {

        let userList = this.state.userList.map(function (item, index) {
            return  <MenuItem key={index} value={item['id']} >
                {item['full_name']}
            </MenuItem>

        }.bind(this));


        return userList;
    };

    onClickBackBtn = () => {
        if(parseInt(this.state.stepComplete) >= 1){
            this.setState({
                stepActive : this.state.stepActive - 1,
                continueButtonActive: true
            })
        }
    };

    submitGoalSetting = () => {
        if(this.state.continueButtonActive){
            if(parseInt(this.state.stepActive) === 6){
                if(!this.state.submitForm){
                    this.setState({
                        submitBtnText : 'Processing. . .',
                        continueBtnIconClassName : "fa fa-clock-o",
                        submitForm : true,
                        goalDurationStart : moment(this.state.goalDurationStart).format('DD/MM/YYYY'),
                        goalDurationEnd : moment(this.state.goalDurationEnd).format('DD/MM/YYYY'),
                    });

                    createNewGoal({data:this.state}).then(res => {
                        if(res.data.success){
                            setTimeout(
                                function() {
                                    this.setState({
                                        submitBtnText : 'Success',
                                        continueBtnIconClassName : "fa fa-smile-o",
                                        submitForm : false,
                                    }, this.closeModalAfterWhile);
                                }.bind(this), 2000);

                            this.props.reloadList(res.data.data.assignee_type , res.data.data.assignee_to);
                        }else{
                            this.setState({
                                submitBtnText : 'Fail!',
                                continueBtnIconClassName : "fa fa-frown-o",
                               errorMessage : 'Something went wrong!'
                            });
                        }

                    });
                }


            }else{
                this.activateCurrentTab(this.state.stepActive + 1)
            }
        }
    };

    activateCurrentTab = (tabVal) => {
        if(parseInt(this.state.stepComplete) >= parseInt(tabVal)){
            this.setState({
                stepActive : tabVal,
            })
        }else{
            if(this.state.continueButtonActive){
                if(parseInt(this.state.stepActive) + 1 === parseInt(tabVal)){
                    this.setState({
                        stepComplete : this.state.stepActive,
                        stepActive : this.state.stepActive + 1,
                        continueButtonActive : false,
                        backBtnClassName : 'btn btn-danger',
                        continueBtnIconClassName : "fa fa-ban",
                    })
                }
            }
        }



    };

    assigneeChange = (e) => {
        this.setState({
            assigneeType : e.target.value,
            continueButtonActive : false,
            continueBtnIconClassName : 'fa fa-ban',
            userListShow : false,
            teamListShow : false,
            assigneeText : 'Not Specified'
        }, this.assigneeSelect);
    };

    assigneeSelect = () => {

        if(parseInt(this.state.assigneeType) === TYPE_COMPANY){
            this.setState({
                continueButtonActive : true,
                continueBtnIconClassName : "fa fa-arrow-right",
                assigneeText : 'Everyone',
                assigneeIcon: 'fa fa-building',
            }, () => this.submitGoalSetting());
            this.getPipeline(2, null, )

        }else if(parseInt(this.state.assigneeType) === TYPE_TEAM){
            this.setState({
                userListShow : false,
                teamListShow : true,
                assigneeIcon: 'fa fa-users'
            });
            this.getPipeline(2, null);
            this.getTeam();
        }else{
            this.setState({
                teamListShow : false,
                userListShow : true,
                assigneeIcon: 'fa fa-user'
            });
            this.getUser();
        }
        this.submitGoalSetting()
    };

    selectUser = (e,child) => {
        if(parseInt(e.target.value) > 0){
            this.setState({
                selectedUser : e.target.value,
                continueButtonActive : true,
                continueBtnIconClassName : "fa fa-arrow-right",
                assigneeText : child.props.children
            }, this.assigneeSelect);
            this.getPipeline(1,e.target.value)
        }

    };

    selectTeam = (e,child) => {
        if(parseInt(e.target.value) > 0){
            this.setState({
                selectedTeam : e.target.value,
                continueButtonActive : true,
                continueBtnIconClassName : "fa fa-arrow-right",
                assigneeText : child.props.children
            }, this.assigneeSelect);
        }

    };

    onChangeGoalType = (e) => {

        this.setState({
            continueButtonActive : false,
        });

        if(parseInt(e.target.value) > 0){
            this.setState({
                selectedGoalType : e.target.value,
                continueButtonActive : false,
            }, this.checkGoalSection);
        }
    };

    onChangeGoalValue = (e) => {
        this.setState({
            continueButtonActive : false,
        });

        if(parseInt(e.target.value) > 0){
            this.setState({
                selectedGoalValue : e.target.value,
            }, this.checkGoalSection);
        }
    };

    checkGoalSection = () => {
        let continueButtonActive = false;
        let continueBtnIconClassName = this.state.continueBtnIconClassName;

        if(parseInt(this.state.selectedGoalType) === GOAL_TYPE_KEY_ACTIVITY_ADDED || parseInt(this.state.selectedGoalType) === GOAL_TYPE_KEY_ACTIVITY_COMPLETED){
            if(parseInt(this.state.selectedGoalValue) > 0){
                continueButtonActive = true;
                continueBtnIconClassName = "fa fa-arrow-right";
            }
        }else{
            continueButtonActive = true;
            continueBtnIconClassName = "fa fa-arrow-right";
        }

        this.setState({
            continueButtonActive : continueButtonActive,
            continueBtnIconClassName : continueBtnIconClassName,
        }, () => {
            if(continueButtonActive){
                this.submitGoalSetting()
            }
        });

        this.changeGoalName();


    };

    changeGoalName = () => {
        let goalIcon = 'fa fa-calendar';
        let goalText = 'Not Specified';

        switch (parseInt(this.state.selectedGoalType)) {
            case GOAL_TYPE_KEY_ACTIVITY_ADDED :
                goalIcon = 'fa fa-calendar';
                goalText = 'Activities Added';
                break;
            case GOAL_TYPE_KEY_ACTIVITY_COMPLETED :
                goalIcon = 'fa fa-calendar';
                goalText = 'Activities Complete';
                break;
            case GOAL_TYPE_KEY_DEALS_STARTED :
                goalIcon = 'fa fa-dollar';
                goalText = 'Deals Started';
                break;
            case GOAL_TYPE_KEY_DEALS_WON :
                goalIcon = 'fa fa-dollar';
                goalText = 'Deals Won';
                break;
            case GOAL_TYPE_KEY_DEALS_LOST :
                goalIcon = 'fa fa-dollar';
                goalText = 'Deals Lost';
                break;
            case GOAL_TYPE_KEY_DEALS_PROGRESSED :
                goalIcon = 'fa fa-dollar';
                goalText = 'Deals Progressed';
                break;
            default:
                break

        }

        if(parseInt(this.state.selectedGoalType) === GOAL_TYPE_KEY_ACTIVITY_ADDED
            || parseInt(this.state.selectedGoalType) === GOAL_TYPE_KEY_ACTIVITY_COMPLETED) {

            if(parseInt(this.state.selectedGoalValue) > 0){

                switch (parseInt(this.state.selectedGoalValue)) {
                    case TASK_CALL :
                        goalIcon = 'fa fa-phone';
                        goalText = goalText + ' '+'(Call)';
                        break;
                    case TASK_EMAIL :
                        goalIcon = 'fa fa-envelope';
                        goalText = goalText + ' '+'(Email)';
                        break;
                    case TASK_FOLLOWUP :
                        goalIcon = 'fa fa-user-plus';
                        goalText = goalText + ' '+'(Followup)';
                        break;
                    case TASK_DEADLINE :
                        goalIcon = 'fa fa-clock-o';
                        goalText = goalText + ' '+'(Deadline)';
                        break;
                    case TASK_MEETING :
                        goalIcon = 'fa fa-calendar';
                        goalText = goalText + ' '+'(Appointment)';
                        break;
                    case TASK_TASK :
                        goalIcon = 'fa fa-check-square-o';
                        goalText = goalText + ' '+'(Task)';
                        break;
                    default:
                        break

                }
            }
        }


        if(this.state.goalTitleEdited){
            this.setState({
                goalTypeIcon : goalIcon,
                goalTypeText : goalText,
            });
        }else{
            this.setState({
                goalTitle : goalText,
                goalTypeText : goalText,
                goalTypeIcon : goalIcon
            });
        }


    };

    onPipelineSelect = (e,child) => {
        let pipelineText = '';
        if(parseInt(e.target.value) > -2){

            pipelineText = child.props.children;

            if(parseInt(this.state.selectedGoalType) !== GOAL_TYPE_KEY_DEALS_PROGRESSED){

                this.setState({
                    pipelineId : e.target.value,
                    continueButtonActive : true,
                    continueBtnIconClassName : "fa fa-arrow-right",
                    goalPipelineText : pipelineText
                }, () => {this.submitGoalSetting()});
            }else{
                this.setState({
                    pipelineId : e.target.value,
                    continueButtonActive : false,
                    goalPipelineText : pipelineText
                });
            }

            if(parseInt(e.target.value) > 0){
                this.getStage(e.target.value);
            }

        }else{
            this.setState({
                pipelineId : null,
                continueButtonActive : false,
                goalPipelineText : pipelineText
            });
        }

    };

    onStageSelect = (e,child) => {
        if(parseInt(e.target.value) > 0){
            this.setState({
                stageId : e.target.value,
                continueButtonActive : true,
                continueBtnIconClassName : "fa fa-arrow-right",
                goalStageText : '-> ' + child.props.children
            }, () =>{this.submitGoalSetting()});
        }else{
            this.setState({
                stageId : null,
                continueButtonActive : false,
                goalStageText : ''
            });
        }

    };

    onSelectExpectedOutcome = (e) => {
        if(parseInt(e.target.value) > 0){
            this.setState({
                expectedOutcomeType : e.target.value,
            }, this.checkExpectedOutcomePanelComplete);
        }else{
            this.setState({
                expectedOutcomeType : null
            }, this.checkExpectedOutcomePanelComplete);
        }
    };

    expectedOutcomeValue = (e) => {
        let outcomeValue = e.target.value.trim();
        if(/^\d+$/.test(outcomeValue)){
            if(outcomeValue.length > 0){
                this.setState({
                    expectedOutcomeValue : outcomeValue
                }, this.checkExpectedOutcomePanelComplete);
            }else{
                this.setState({
                    expectedOutcomeValue : ''
                }, this.checkExpectedOutcomePanelComplete);
            }
        }
        else{
            this.setState({
                expectedOutcomeValue : ''
            }, this.checkExpectedOutcomePanelComplete);
        }
    };

    checkExpectedOutcomePanelComplete = () => {
        let goalExpectedOutComeText = '';
        if((parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_ACTIVITY_ADDED) ||
            (parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_ACTIVITY_COMPLETED)){
            this.setState({
                continueButtonActive : true,
                continueBtnIconClassName : "fa fa-arrow-right",
                goalExpectedOutComeText : this.state.expectedOutcomeValue + ' '+ 'activities'
            }, () => {
                if(this.state.stepActive !== 4){
                    this.submitGoalSetting()
                }
            });
        }else{
            if(this.state.expectedOutcomeType && this.state.expectedOutcomeValue){
                if(parseInt(this.state.expectedOutcomeType) === EXPECTED_OUTCOME_AMOUNT){
                    goalExpectedOutComeText = this.state.expectedOutcomeValue + ' deals';
                }else{
                    goalExpectedOutComeText = CoreConstants.DEAL_CURRENCY +' '+ this.state.expectedOutcomeValue
                }

                this.setState({
                    continueButtonActive : true,
                    continueBtnIconClassName : "fa fa-arrow-right",
                    goalExpectedOutComeText : goalExpectedOutComeText
                }, () => {
                    if(this.state.stepActive !== 4){
                        this.submitGoalSetting()
                    }
                });
            }
        }

    };

    onChangeGoalInterval = (e,child) => {
        if(parseInt(e.target.value) > 0){

            this.setState({
                continueButtonActive : true,
                continueBtnIconClassName : "fa fa-arrow-right",
                goalIntervalText : child.props.children,
                goalIntervalOption : e.target.value,
            }, () => {this.submitGoalSetting()});
        }
    };

    onChangeGoalDuration = (e) => {
        if(parseInt(e.target.value) > 0){

            let goalDurationText = 'Not Specified';

            let month = moment().format('M');
            let day   = moment().format('D');
            let year  = moment().format('YYYY');
            let quarter = moment().quarter();
            let editableDuration = false;
            let goalDurationStart = moment().startOf('month').format('DD/MM/YYYY');
            let goalDurationEnd =  moment().endOf('month').format('DD/MM/YYYY');
            let startOfMonth = goalDurationStart;
            let endOfMonth = goalDurationEnd;

            switch (parseInt(e.target.value)) {

                case GOAL_DURATION_TYPE_THIS_MONTH :
                    startOfMonth = moment().startOf('month').format('DD/MM/YYYY');
                    endOfMonth   = moment().endOf('month').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    break;
                case GOAL_DURATION_TYPE_NEXT_MONTH :
                    startOfMonth = moment().add(1, 'months').startOf('month').format('DD/MM/YYYY');
                    endOfMonth   =moment().add(1, 'months').endOf('month').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    break;
                case GOAL_DURATION_TYPE_THIS_WEEK :
                    startOfMonth = moment().startOf('week').format('DD/MM/YYYY');
                    endOfMonth   = moment().endOf('week').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    break;
                case GOAL_DURATION_TYPE_NEXT_WEEK :
                    startOfMonth = moment().add(1, 'weeks').startOf('week').format('DD/MM/YYYY');
                    endOfMonth   =moment().add(1, 'weeks').endOf('week').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    break;
                case GOAL_DURATION_TYPE_THIS_QUARTER :
                    startOfMonth = moment().quarter(quarter).startOf('quarter').format('DD/MM/YYYY');
                    endOfMonth = moment().quarter(quarter).endOf('quarter').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    break;
                case GOAL_DURATION_TYPE_NEXT_QUARTER :
                    startOfMonth = moment().quarter(quarter + 1).startOf('quarter').format('DD/MM/YYYY');
                    endOfMonth = moment().quarter(quarter + 1).endOf('quarter').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    break;
                case GOAL_DURATION_TYPE_THIS_YEAR :
                    startOfMonth = moment().startOf('year').format('DD/MM/YYYY');
                    endOfMonth   = moment().endOf('year').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    break;
                case GOAL_DURATION_TYPE_NEXT_YEAR :
                    startOfMonth = moment().startOf('year').format('DD/MM/YYYY');
                    endOfMonth   = moment().endOf('year').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    break;
                case GOAL_DURATION_TYPE_DATE_RANGE :
                    startOfMonth = moment().startOf('month').format('DD/MM/YYYY');
                    endOfMonth   = moment().endOf('month').format('DD/MM/YYYY');
                    goalDurationText = startOfMonth +' - '+endOfMonth;
                    goalDurationStart = startOfMonth;
                    goalDurationEnd = endOfMonth;
                    editableDuration = true;
                    break;
                default:
                    break;

            }

            this.setState({
                continueButtonActive : true,
                submitBtnText : 'Add Goal',
                continueBtnIconClassName : "fa fa-plus",
                goalDurationText : goalDurationText,
                goalDurationOption : parseInt(e.target.value),
                editableDuration : editableDuration,
                goalDurationStart : goalDurationStart,
                goalDurationEnd : goalDurationEnd,
            }, () => {
                // this.submitGoalSetting()
            });
        }
    };

    handleChange = date => {
        this.setState({
            firstDay: date,
            goalDurationStart : moment(date).format('DD/MM/YYYY'),
            goalDurationText :  moment(date).format('DD/MM/YYYY') +' - '+this.state.goalDurationEnd
        });
    };

    handleChangeDateEnd = (date) => {
        this.setState({
            lastDay: date,
            goalDurationEnd :  moment(date).format('DD/MM/YYYY'),
            goalDurationText : this.state.goalDurationStart +' - '+ moment(date).format('DD/MM/YYYY'),
        });
    };

    editStatusToggle = () => {
        this.setState({
            goalTitleEditMode : !this.state.goalTitleEditMode
        })
    };

    onGoalTitleChange = (e) => {
        this.setState({
            goalTitle : e.target.value
        })
    };

    onGoalTitleSave = () => {
        this.setState({
            goalTitlePrevious : this.state.goalTitle,
            goalTitleEdited : true
        }, this.editStatusToggle);
    };

    onGoalTitleCancel = () => {
        this.setState({
            goalTitle : this.state.goalTitlePrevious
        }, this.editStatusToggle);
    };

    closeModal = () => {
        this.props.hideModal();
    };

    closeModalAfterWhile = () => {
        setTimeout(
            function() {
                this.closeModal();
            }.bind(this), 1000);
    };

    renderNextButtonIcon = () => {
        if(this.state.stepActive === 4) {
            return <ArrowForward/>
        } else if (this.state.stepActive === 6) {
            if(this.state.continueButtonActive) {
                return <Add/>
            } else {
                return <Block/>
            }
        }
        return '';
    }

    renderNextButtonText = () => {
        if(this.state.stepActive === 4) {
            return 'Next';
        } else if (this.state.stepActive === 6) {
            return this.state.submitBtnText + ' '
        }
        return '';
    }

    render() {

        return (
            <>
                <GlobalModal 
                    open={this.props.isOpen} 
                    onClose={() => this.closeModal()}
                    title={"Add goal"}
                    bodyClass={'modal__body__goal'}
                    wrapperClass={'globalMoalWrapper__goal'}
                    showBackButton={this.state.stepActive !== 1}
                    backButtonText={'Back'}
                    backButtonIcon={<ArrowBack/>}
                    onBackSubmit={this.onClickBackBtn}
                    hideSubmitButton={this.renderNextButtonText() === ''}
                    buttonText={this.renderNextButtonText()}
                    buttonIcon={this.renderNextButtonIcon()}
                    onSubmit={this.submitGoalSetting}
                    errorMessage={this.state.errorMessage}>

                    <form action="/">
                        <div className="add__goal_content__wr">
                            <div className="add__goal_steps__wr">

                                {/* Step 1 */}
                                <div className="add__goal_item">
                                    <div className="step__label__icon" /* onClick={this.submitGoalSetting} */>
                                        { (this.state.stepComplete >= 1) ? iconList.checkMark : iconList.circle }
                                    </div>
                                    <div className="step__level__select__content__wr">
                                        <div className={`step__label__name ${this.state.stepActive == 1 ? 'assigne__label__name' : ''}`}>Assignee</div>
                                    </div>
                                </div>
                                <If condition={this.state.stepActive == 1}>
                                    <div className="setp__label__left__border">
                                        <h4 className="step__label__name__title">Who is this goal assigned to?</h4>
                                        <div className="step__select__content">
                                            <div className="add__goal__select1">
                                                <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                    <SelectComponent
                                                        onChange={this.assigneeChange}
                                                        value={this.state.assigneeType}>
                                                        <MenuItem value={""} disabled>---Select---</MenuItem>
                                                        <MenuItem value={TYPE_COMPANY}>Company (everyone)</MenuItem>
                                                        <MenuItem value={TYPE_TEAM}>Team</MenuItem>
                                                        <MenuItem value={TYPE_USER}>User</MenuItem>
                                                    </SelectComponent>
                                                </FormControl>
                                            </div>
                                                
                                            <If condition={this.state.userListShow}>
                                                <div className="add__goal__select2">
                                                    <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                        <SelectComponent
                                                            value={this.state.selectedUser}
                                                            onChange={this.selectUser}>
                                                            <MenuItem value="0">Choose user</MenuItem>
                                                            {this.userList()}
                                                        </SelectComponent>
                                                    </FormControl>
                                                </div>
                                            </If>

                                            <If condition={this.state.teamListShow}>
                                                <div className="add__goal__select2">
                                                    <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                        <SelectComponent
                                                            value={this.state.selectedTeam}
                                                            onChange={this.selectTeam}>
                                                            <MenuItem value="0">Choose Teams</MenuItem>
                                                            {this.teamList()}
                                                        </SelectComponent>
                                                    </FormControl>
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                </If>
                                <If condition={this.state.stepComplete >= 1 && this.state.stepActive != 1}>
                                    <div className="">
                                      <h4 className="active__border__green pro__lead__group">{this.state.assigneeText}</h4>
                                    </div>
                                </If>


                                
                                {/* Step 2 */}
                                <div className={`add__goal_item ${this.state.stepComplete < 2 ? 'mb-2' : ''}`}>
                                    <div className="step__label__icon" onClick={this.submitGoalSetting}>
                                        { (this.state.stepComplete >= 2) ? iconList.checkMark : iconList.circle }
                                      </div>
                                      <div className="step__level__select__content__wr">
                                        <div className={`step__label__name ${this.state.stepActive == 2 ? 'assigne__label__name' : ''}`}>Goal type</div>
                                      </div>
                                </div>

                                <If condition={this.state.stepActive == 2}>
                                    <div className="setp__label__left__border">
                                        <h4 className="step__label__name__title">What would you like to track?</h4>
                                        <div className="step__select__content">
                                            <div className="add__goal__select1">
                                                <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                    <SelectComponent
                                                        onChange={this.onChangeGoalType}
                                                        value={this.state.selectedGoalType}>
                                                        <MenuItem value={0}>Select Goal Type</MenuItem>
                                                        <MenuItem value={GOAL_TYPE_KEY_ACTIVITY_ADDED}>Activities added</MenuItem>
                                                        <MenuItem value={GOAL_TYPE_KEY_ACTIVITY_COMPLETED}>Activities completed</MenuItem>
                                                        <MenuItem value={GOAL_TYPE_KEY_DEALS_STARTED}>Deals started</MenuItem>
                                                        <MenuItem value={GOAL_TYPE_KEY_DEALS_WON}>Deals won</MenuItem>
                                                        <MenuItem value={GOAL_TYPE_KEY_DEALS_LOST}>Deals lost</MenuItem>
                                                        <MenuItem value={GOAL_TYPE_KEY_DEALS_PROGRESSED}>Deal Progress</MenuItem>
                                                    </SelectComponent>
                                                </FormControl>
                                            </div>
                                                
                                            <If condition={(parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_ACTIVITY_ADDED) || (parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_ACTIVITY_COMPLETED)}>
                                                <div className="add__goal__select2">
                                                    <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                        <SelectComponent
                                                            value={this.state.selectedGoalValue}
                                                            onChange={this.onChangeGoalValue}>
                                                            <MenuItem value="0">Activity type</MenuItem>
                                                            <MenuItem value={TASK_CALL}>Call</MenuItem>
                                                            <MenuItem value={TASK_MEETING}>Appointment</MenuItem>
                                                            <MenuItem value={TASK_TASK}>Task</MenuItem>
                                                            <MenuItem value={TASK_EMAIL}>Email</MenuItem>
                                                        </SelectComponent>
                                                    </FormControl>
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                </If>
                                <If condition={this.state.stepComplete >= 2 && this.state.stepActive != 2}>
                                    <div className="">
                                      <h4 className="active__border__green pro__lead__group">{this.state.goalTitle}</h4>
                                    </div>
                                </If>

                                

                                {/* Step 3 */}
                                <div className={`add__goal_item ${this.state.stepComplete < 3 ? 'mb-2' : ''}`}>
                                    <div className="step__label__icon">
                                        { (this.state.stepComplete >= 3) ? iconList.checkMark : iconList.circle }
                                      </div>
                                      <div className="step__level__select__content__wr">
                                        <div className={`step__label__name ${this.state.stepActive == 3 ? 'assigne__label__name' : ''}`}>Pipeline</div>
                                      </div>
                                </div>

                                <If condition={this.state.stepActive == 3}>
                                    <div className="setp__label__left__border">
                                        <h4 className="step__label__name__title">Which pipeline does this goal apply to?</h4>
                                        <div className="step__select__content">
                                            <div className="add__goal__select1">
                                                <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                    <SelectComponent
                                                        onChange={this.onPipelineSelect}
                                                        value={this.state.pipelineId == null ? -2 : this.state.pipelineId}>
                                                        <MenuItem value="-2">Select Pipeline</MenuItem>
                                                        {
                                                            ((parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_ACTIVITY_ADDED) || (parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_ACTIVITY_COMPLETED)) &&
                                                            <MenuItem value={NO_SPECIFIC_PIPELINE}>No Specific Pipeline</MenuItem>
                                                        }
                                                        {
                                                            ((parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_DEALS_WON) || (parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_DEALS_LOST) || (parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_DEALS_STARTED)) && 
                                                            <MenuItem value={ALL_PIPELINE}>All Pipeline</MenuItem>
                                                        }
                                                        {this.pipelineList()}
                                                    </SelectComponent>
                                                </FormControl>
                                            </div>
                                                
                                            <If condition={(parseInt(this.state.selectedGoalType) ===  GOAL_TYPE_KEY_DEALS_PROGRESSED)}>
                                                <div className="add__goal__select2">
                                                    <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                        <SelectComponent
                                                            value={this.state.stageId == null ? 0 : this.state.stageId}
                                                            onChange={this.onStageSelect}>
                                                            <MenuItem value="0">Select Stage</MenuItem>
                                                            {this.stageList()}
                                                        </SelectComponent>
                                                    </FormControl>
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                </If>
                                <If condition={this.state.stepComplete >= 3 && this.state.stepActive != 3}>
                                    <div className="">
                                      <h4 className="active__border__green pro__lead__group">{this.state.goalPipelineText + " " + this.state.goalStageText}</h4>
                                    </div>
                                </If>



                                {/* Step 4 */}
                                <div className={`add__goal_item ${this.state.stepComplete < 4 ? 'mb-2' : ''}`}>
                                    <div className="step__label__icon">
                                        { (this.state.stepComplete >= 4) ? iconList.checkMark : iconList.circle }
                                      </div>
                                      <div className="step__level__select__content__wr">
                                        <div className={`step__label__name ${this.state.stepActive == 4 ? 'assigne__label__name' : ''}`}>Expected outcome</div>
                                      </div>
                                </div>

                                <If condition={this.state.stepActive == 4}>
                                    <div className="setp__label__left__border">
                                        <h4 className="step__label__name__title">What is the desired target for this goal?</h4>
                                        <div className="step__select__content">
                                            <div className="add__goal__select1">
                                                <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                    <input placeholder="Insert value" type="number" defaultValue={this.state.expectedOutcomeValue}
                                                                className="form-control" onChange={e => this.expectedOutcomeValue(e)} />
                                                </FormControl>
                                            </div>
                                                
                                            <If condition={(parseInt(this.state.selectedGoalType) !==  GOAL_TYPE_KEY_ACTIVITY_ADDED) && (parseInt(this.state.selectedGoalType) !==  GOAL_TYPE_KEY_ACTIVITY_COMPLETED)}>
                                                <div className="add__goal__select2">
                                                    <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                        <SelectComponent
                                                            value={this.state.expectedOutcomeType}
                                                            onChange={this.onSelectExpectedOutcome}>
                                                            <MenuItem value={EXPECTED_OUTCOME_AMOUNT}>Amount (deals)</MenuItem>
                                                            <MenuItem value={EXPECTED_OUTCOME_VALUE}>Value (USD)</MenuItem>
                                                        </SelectComponent>
                                                    </FormControl>
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                </If>
                                <If condition={this.state.stepComplete >= 4 && this.state.stepActive != 4}>
                                    <div className="">
                                      <h4 className="active__border__green pro__lead__group">{this.state.goalExpectedOutComeText}</h4>
                                    </div>
                                </If>


                                {/* Step 5 */}
                                <div className={`add__goal_item ${this.state.stepComplete < 5 ? 'mb-2' : ''}`}>
                                    <div className="step__label__icon">
                                        { (this.state.stepComplete >= 5) ? iconList.checkMark : iconList.circle }
                                      </div>
                                      <div className="step__level__select__content__wr">
                                        <div className={`step__label__name ${this.state.stepActive == 5 ? 'assigne__label__name' : ''}`}>Goal interval</div>
                                      </div>
                                </div>

                                <If condition={this.state.stepActive == 5}>
                                    <div className="setp__label__left__border">
                                        <h4 className="step__label__name__title">How frequently do you want to measure this outcome?</h4>
                                        <div className="step__select__content">
                                            <div className="add__goal__select1">
                                                <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                    <SelectComponent
                                                        value={this.state.goalIntervalOption}
                                                        onChange={this.onChangeGoalInterval}>
                                                        <MenuItem value="0">Select Goal Interval</MenuItem>
                                                        <MenuItem value={GOAL_INTERVAL_WEEKLY}>Weekly</MenuItem>
                                                        <MenuItem value={GOAL_INTERVAL_MONTHLY}>Monthly</MenuItem>
                                                        <MenuItem value={GOAL_INTERVAL_QUARTERLY}>Quarterly</MenuItem>
                                                        <MenuItem value={GOAL_INTERVAL_YEARLY}>Yearly</MenuItem>
                                                    </SelectComponent>
                                                </FormControl>
                                            </div>
                                            <div className="add__goal__select2 d-flex">
                                                <Tooltip 
                                                    arrow
                                                    placement="right" 
                                                    title="Goals are adjusted based on the time interval
                                                                    selected in Reports and Dashboard.
                                                                    E.g., a goal to won 10 deals monthly will adjust
                                                                    to 120 when a yearly filter is applied.">
                                                    <svg className="icon-info-outline" width="16" heigth="16" viewBox="0 0 16 16"><path
                                                        d="M7 12h2V7H7v5zM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 14a6.01 6.01 0 1 1 .02-12.02A6.01 6.01 0 0 1 8 14zM7 6h2V4H7v2z"></path></svg>
                                                </Tooltip>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </If>
                                <If condition={this.state.stepComplete >= 5 && this.state.stepActive != 5}>
                                    <div className="">
                                      <h4 className="active__border__green pro__lead__group">{this.state.goalIntervalText}</h4>
                                    </div>
                                </If>


                                {/* Step 6 */}
                                <div className={`add__goal_item ${this.state.stepComplete < 6 ? 'mb-2' : ''}`}>
                                    <div className="step__label__icon">
                                        { (this.state.stepComplete >= 6) ? iconList.checkMark : iconList.circle }
                                      </div>
                                      <div className="step__level__select__content__wr">
                                        <div className={`step__label__name ${this.state.stepActive == 6 ? 'assigne__label__name' : ''}`}>Goal duration</div>
                                      </div>
                                </div>

                                <If condition={this.state.stepActive == 6}>
                                    <div className="setp__label__left__border">
                                        <h4 className="step__label__name__title">How long will this goal stay active?</h4>
                                        <div className="step__select__content">
                                            <div className="add__goal__select1">
                                                <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                                    <SelectComponent
                                                        value={this.state.goalDurationOption}
                                                        onChange={this.onChangeGoalDuration}>
                                                        <MenuItem value="0">Goal Duration</MenuItem>
                                                        <MenuItem value={GOAL_DURATION_TYPE_THIS_MONTH}>This Month</MenuItem>
                                                        <MenuItem value={GOAL_DURATION_TYPE_NEXT_MONTH}>Next Month</MenuItem>
                                                        <MenuItem value={GOAL_DURATION_TYPE_THIS_WEEK}>This Week</MenuItem>
                                                        <MenuItem value={GOAL_DURATION_TYPE_NEXT_WEEK}>Next Week</MenuItem>
                                                        {/* <MenuItem value={GOAL_DURATION_TYPE_THIS_QUARTER}>This quarter</MenuItem> */}
                                                        {/* <MenuItem value={GOAL_DURATION_TYPE_NEXT_QUARTER}>Next quarter</MenuItem> */}
                                                        <MenuItem value={GOAL_DURATION_TYPE_THIS_YEAR}>This Year</MenuItem>
                                                        <MenuItem value={GOAL_DURATION_TYPE_NEXT_YEAR}>Next Year</MenuItem>
                                                        {/* <MenuItem value={GOAL_DURATION_TYPE_DATE_RANGE}>Custom period</MenuItem> */}
                                                    </SelectComponent>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </If>
                                <If condition={this.state.stepComplete >= 6 && this.state.stepActive != 6}>
                                    <div className="">
                                      <h4 className="active__border__green pro__lead__group">{this.state.goalDurationText}</h4>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </form>
                </GlobalModal>
            </>
        );
    }
}

export default GoalModal;

const SelectComponent = (props) => {
    return <Select
        className="goal_select goal_select__modal"
        labelId="demo-simple-select-outlined-label"
        value={props.value}
        onChange={props.onChange}
        IconComponent = {ArrowDropDownIcon}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        MenuProps={{
            anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
            },
            transformOrigin: {
            vertical: "top",
            horizontal: "left"
            },
            getContentAnchorEl: null
        }}
        >
        {props.children}
    </Select>
}
