import { FormControl, MenuItem } from "@material-ui/core";
import { useState } from "react";
import { IntervalTypes } from "../../../constants/CoreConstants";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { iconList } from "../../globals/IconList";
import SelectComponent from "../SelectComponent";

const StepFifth = props => {

    const [goalIntervalOption,setGoalIntervalOption] = useState(0);
    const [intervalText,setIntervalText] = useState(0);

    const onChangeIntervalOption = (e,elem) => {
        setGoalIntervalOption(e.target.value);
        setIntervalText(elem.props.children);
        let isValid = e.target.value !== 0;
        props.validateStep(5,isValid,{
            goalIntervalOption : e.target.value
        });
    }
    
    return ( 
        <>
        <div className={`add__goal_item ${props.stepComplete < 5 ? 'mb-2' : ''}`}>
            <div className="step__label__icon">
                { (props.stepComplete >= 5) ? iconList.checkMark : iconList.circle }
            </div>
            <div className="step__level__select__content__wr">
                <div className={`step__label__name ${props.stepActive == 5 ? 'assigne__label__name' : ''}`}>Goal interval</div>
            </div>
        </div>

        { props.stepActive == 5 &&
            <div className="setp__label__left__border">
                <h4 className="step__label__name__title">How frequently do you want to measure this outcome?</h4>
                <div className="step__select__content">
                    <div className="add__goal__select1">
                        <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                            <SelectComponent
                                value={goalIntervalOption}
                                onChange={onChangeIntervalOption}>
                                <MenuItem value="0" disabled>Select Goal Interval</MenuItem>
                                <MenuItem value={IntervalTypes.GOAL_INTERVAL_WEEKLY}>Weekly</MenuItem>
                                <MenuItem value={IntervalTypes.GOAL_INTERVAL_MONTHLY}>Monthly</MenuItem>
                                <MenuItem value={IntervalTypes.GOAL_INTERVAL_QUARTERLY}>Quarterly</MenuItem>
                                <MenuItem value={IntervalTypes.GOAL_INTERVAL_YEARLY}>Yearly</MenuItem>
                            </SelectComponent>
                        </FormControl>
                    </div>
                    <div className="add__goal__select2 d-flex">
                        <BootstrapTooltip arrow placement="right" title="Goals are adjusted based on the time interval
                                            selected in Reports and Dashboard.
                                            E.g., a goal to won 10 deals monthly will adjust
                                            to 120 when a yearly filter is applied.">
                            <svg className="icon-info-outline" width="16" heigth="16" viewBox="0 0 16 16"><path
                                    d="M7 12h2V7H7v5zM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 14a6.01 6.01 0 1 1 .02-12.02A6.01 6.01 0 0 1 8 14zM7 6h2V4H7v2z"></path></svg>
                        </BootstrapTooltip>
                    </div>
                </div>
            </div>
        }

        { props.stepComplete >= 5 && props.stepActive != 5 &&
            <div className="">
                <h4 className="active__border__green pro__lead__group">{intervalText}</h4>
            </div>
        }
        </>
    );
}
 
export default StepFifth;