import React, { Component } from 'react';
import {connect} from "react-redux";
import { getData } from '../../actions/reportAction.js';
import { AllDateFilters } from '../../constants/CoreConstants.js';
import { FormControl,Select,MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

function mapDispatchToProps(dispatch) {
    return {
        changeType: (dateType) => dispatch(getData(dateType)),
    };
}


const mapStateToProps = state => {
    return {
        currentDateFilterID: state.reportReducer.currentDateFilterID
    };
};


const DateFilter = (props) => {

    const selectType = ({id,title})=>{
        props.changeType({
            id,
            title
        });
    };

    const allTypes = () => {
        const allTypes = [];
        for(let i in AllDateFilters){
            allTypes.push(<MenuItem key={i} className={'repot_dropdown_item'} value={i}>{AllDateFilters[i]}</MenuItem>);
        }
        return allTypes;
    }


    return (
        <FormControl variant="outlined" margin="none" focused="false" className="business_select__wr">
            <Select
                className="business_select select__blue__back accent--bg--color"
                labelId="demo-simple-select-outlined-label"
                value={props.currentDateFilterID}
                onChange={(e,data) => selectType({id:data.props.value,title:data.props.children})}
                IconComponent = {KeyboardArrowDown}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                getContentAnchorEl: null
                }}
            >
                {allTypes()}
            </Select>
        </FormControl>
    );
}


const Filter = connect(mapStateToProps, mapDispatchToProps)(DateFilter);

export default Filter;
