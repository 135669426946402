import { FormControl, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { AssigneeTypes, GoalTypes, TaskTypes } from "../../../constants/CoreConstants";
import { useIsMounted } from "../../../hooks/IsMounted";
import { iconList } from "../../globals/IconList";
import SelectComponent from "../SelectComponent";


const StepSecond = props => {

    const isMounted = useIsMounted();
    const [selectedGoalType,setSelectedGoalType] = useState(0);
    const [selectedGoalValue,setSelectedGoalValue] = useState(0);
    const [goalTypeText,setGoalTypeText] = useState('');
    const [goalValueText,setGoalValueText] = useState('');
    const [validStep,setValidStep] = useState(false);
    const [goalText,setGoalText] = useState('');

    useEffect(() => {
        if(isMounted) {
            let newGoalText = goalTypeText + ((selectedGoalType === GoalTypes.GOAL_TYPE_KEY_ACTIVITY_ADDED || 
                selectedGoalType === GoalTypes.GOAL_TYPE_KEY_ACTIVITY_COMPLETED) ? (' - ' + goalValueText) : '');
            setGoalText(newGoalText);
            let isValid = selectedGoalType > 2 ||  selectedGoalValue !== 0;
            setValidStep(isValid);
            props.validateStep(2,isValid,{
                selectedGoalType : selectedGoalType,
                selectedGoalValue : selectedGoalValue,
                goalTitle : goalTypeText
            });
        }
    },[selectedGoalType,selectedGoalValue]);

    return ( 
        <>

        <div className={`add__goal_item ${props.stepComplete < 2 ? 'mb-2' : ''}`}>
            <div className="step__label__icon">
                { validStep ? iconList.checkMark : iconList.circle }
                </div>
                <div className="step__level__select__content__wr">
                <div className={`step__label__name ${props.stepActive == 2 ? 'assigne__label__name' : ''}`}>Goal type</div>
                </div>
        </div>

        { props.stepActive == 2 &&
            <div className="setp__label__left__border">
                <h4 className="step__label__name__title">What would you like to track?</h4>
                <div className="step__select__content">
                    <div className="add__goal__select1">
                        <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                            <SelectComponent
                                onChange={(e,elem) => {setSelectedGoalType(e.target.value);setGoalTypeText(elem.props.children)}}
                                value={selectedGoalType}>
                                <MenuItem value={0} disabled>Select Goal Type</MenuItem>
                                <MenuItem value={GoalTypes.GOAL_TYPE_KEY_ACTIVITY_ADDED}>Activities added</MenuItem>
                                <MenuItem value={GoalTypes.GOAL_TYPE_KEY_ACTIVITY_COMPLETED}>Activities completed</MenuItem>
                                <MenuItem value={GoalTypes.GOAL_TYPE_KEY_DEALS_STARTED}>Deals started</MenuItem>
                                <MenuItem value={GoalTypes.GOAL_TYPE_KEY_DEALS_WON}>Deals won</MenuItem>
                                <MenuItem value={GoalTypes.GOAL_TYPE_KEY_DEALS_LOST}>Deals lost</MenuItem>
                                {props.postData.assigneeType !== AssigneeTypes.TYPE_USER &&
                                <MenuItem value={GoalTypes.GOAL_TYPE_KEY_DEALS_PROGRESSED}>Deal Progress</MenuItem>
                                }
                            </SelectComponent>
                        </FormControl>
                    </div>
                        
                    { (selectedGoalType ===  GoalTypes.GOAL_TYPE_KEY_ACTIVITY_ADDED || selectedGoalType ===  GoalTypes.GOAL_TYPE_KEY_ACTIVITY_COMPLETED) &&
                        <div className="add__goal__select2">
                            <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                <SelectComponent
                                    value={selectedGoalValue}
                                    onChange={(e,elem) => {setSelectedGoalValue(e.target.value);setGoalValueText(elem.props.children)}}>
                                    <MenuItem value="0" disabled>Activity type</MenuItem>
                                    <MenuItem value={TaskTypes.TASK_CALL}>Call</MenuItem>
                                    <MenuItem value={TaskTypes.TASK_MEETING}>Appointment</MenuItem>
                                    <MenuItem value={TaskTypes.TASK_TASK}>Task</MenuItem>
                                    <MenuItem value={TaskTypes.TASK_EMAIL}>Email</MenuItem>
                                </SelectComponent>
                            </FormControl>
                        </div>
                    }
                </div>
            </div>
        }
        { props.stepComplete >= 2 && props.stepActive != 2 &&
            <div className="">
                <h4 className="active__border__green pro__lead__group">{goalText}</h4>
            </div>
        }
        </>
     );
}
 
export default StepSecond;