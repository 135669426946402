import React from 'react';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    BarChart,
    Bar,
    ResponsiveContainer,
    ReferenceLine,
    Cell
} from 'recharts';
import { getGoalStatistics } from '../../../../api/goalApi';
import If from 'if-else-react';
import GoalSkeleton from '../../../common/Skeletons/GoalSkeleton';

class TypeReportTab extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            userData:[],
            responseLength:0,
            duration:this.props.selectedDateType,
            teamUser:0,
            totalCount:0,
            loading:true,
        }
    }
    loadChart=()=>{
        getGoalStatistics({
            type:4,
            chart:'byType',
            duration:this.props.selectedDateType,
            pipeline:this.props.selectedPipeline,
            teamUser:this.props.selectedTeamUser,
            from:this.props.selectedDateFrom,
            to:this.props.selectedDateTo,
        }).then(
                response  => {
                    let res = response.data;
                    let chartData=[];
                    let totalCount=0;
                    if(res.length>0){
                        res.map(function(data,index){
                            if (data.content_type == 2) {
                                totalCount +=data.total_count;
                                chartData.push({
                                    name: 'SMS',
                                    count: data.total_count
                                });
                            } else if (data.content_type == 3) {
                                totalCount +=data.total_count;
                                chartData.push({
                                    name: 'Email',
                                    count: data.total_count
                                });
                            } else if (data.content_type == 4) {
                                totalCount +=data.total_count;
                                chartData.push({
                                    name: 'MMS',
                                    count: data.total_count
                                });
                            } else if (data.content_type == 5) {
                                totalCount +=data.total_count;
                                chartData.push({
                                    name: 'Call',
                                    count: data.total_count
                                });
                            }

                        });
                    }
                    this.setState({data:chartData,userData:res,totalCount:totalCount, loading:false});
                }
            );
    }
    populateTable = () => {
        return this.state.data.map((value, index) => {
            return <tr key={index}>
                <td>{value.name}</td>
                <td>{value.count}</td>
                <td>{((value.count/this.state.totalCount)*100).toFixed(1)+'%'}</td>
            </tr>;
        });
    }
    componentDidMount(){
        this.loadChart();
    }

    componentDidUpdate(){
        if(this.props.activeTab=='by-type' && this.state.duration!=this.props.selectedDateType){
            this.setState({duration:this.props.selectedDateType},() => {
                this.loadChart();
            })
        }else if(this.props.activeTab=='by-type' && this.state.teamUser!=this.props.selectedTeamUser){
            this.setState({teamUser:this.props.selectedTeamUser},() => {
                this.loadChart();
            })
        }
    }

    loadingPanel(){
        if (this.state.loading){
            return (
                <GoalSkeleton/>);
        }else{
            return (
                <div className="goal_no_data_text_wrapper">
                    <div>
                        <h2 className="no_data__loading_title text-center">No activities have been added during the chosen period</h2>
                        <br/>
                        <p className="no_data__loading_subtitle">Try selecting another pipeline or a longer period</p>
                    </div>
                </div>
            );
        }
    }

    customTooltipChart = ({ payload, label, active }) => {
        let icons = {
            sms:"fa fa-envelope-o",
            mms:"fa fa-image",
            call:"fa fa-phone",
            email:"fa fa-envelope",
        }
        if (active) {
            return (
                <div className="custom-tooltip" style={{backgroundColor:"#ffffff",padding:10,borderRadius:5,fontSize:13,lineHeight:1}}>
                    <p className="label" style={{fontSize:14}}><i className={icons[label.toLowerCase()]}></i> {`${label}`}</p>
                    <p className="intro my-0">
                        Total&nbsp;<span style={{float:"right"}}>{`${payload[0].value}`}</span></p>
                </div>
            );
        }
        return null;
    }

    render() {
        return(
            <div>
            <If condition={this.props.activeTab == 'by-type'}>
                <div className="tab-pane fade in active show">
                    <div className="tabs__content-action">
                        <div className="action_lists">
                            <div className="action_list">
                                {/*<div className="action__input action__input__radio">
                                    <label className="widget-radio active"><input type="radio" name="viewType" value="count"
                                                                                  checked="&quot;checked&quot;/"/>Count</label>
                                    <label className="widget-radio"><input type="radio" name="viewType" value="value"/>Value</label>
                                </div>
                                <div className="action__input action__input__radio">
                                    <label className="widget-radio"><input type="radio" name="userType" value="creator"/>Deal
                                        creator</label>
                                    <label className="widget-radio active"><input type="radio" name="userType" value="owner"
                                                                                  checked="&quot;checked&quot;/"/>Current
                                        owner</label>
                                </div>
                                {/*<div className="action__input action__input__checkbox">*/}
                                {/*    <label className="checkbox">*/}
                                {/*        <input type="checkbox" name="" value="1"/>*/}
                                {/*        <span className="checkbox__label">Divide by current status</span>*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                            </div>
                            <div className="action_list">
                                    <div style={{width:"100%",height:400}}>
                                        {(this.state.data.length===0||this.state.loading)?this.loadingPanel():
                                            <ResponsiveContainer>
                                                <BarChart
                                                    layout="vertical"
                                                    data={this.state.data}
                                                    margin={{
                                                        top: 20, right: 30, left: 20, bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis type="number" tick={{fontSize: 14}}/>
                                                    <YAxis dataKey="name" type="category" tick={{fontSize: 14}}/>
                                                    <Tooltip content={this.customTooltipChart}/>
                                                    {/*<Legend wrapperStyle={{fontSize:14}}/>*/}
                                                    <ReferenceLine y={4} label="Max" stroke="red" />
                                                    <Bar dataKey="count">
                                                        {
                                                            this.state.data.map((entry, index) => (
                                                                <Cell fill={this.props.barColors[entry.name.toLowerCase()]}/>
                                                            ))
                                                        }
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>}
                                </div>
                            </div>
                            <If condition={this.state.data.length !== 0}>
                                <div className="action_list">
                                    <table className={'mt-5 table table-bordered text-center'}>
                                        <thead><th>Communication Type</th><th>Count</th><th>Percentage</th></thead>
                                        <tbody><tr><td>All Types</td><td>{this.state.totalCount}</td><td>100%</td></tr>{this.populateTable()}</tbody>
                                    </table>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
            </If>
            </div>
        );
    }
}

export default TypeReportTab;
