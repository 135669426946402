import React from 'react';
import moment from 'moment';
import {Goal} from './Constants'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import If from 'if-else-react';
import { Link } from 'react-router-dom';
import GlobalPopover from '../globals/GlobalPopover';
import Select from '@material-ui/core/Select';
import Utils from '../../helpers/Utils';
import { AssigneeTypes, GoalTypes } from '../../constants/CoreConstants';


const date = new Date();
class SingleGoal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    };

    getInitialState = () => {
        return {
            openGoalTitleForm:false,
            goalTitleOption: null,

            openGoalIntervalForm:false,
            goalIntervalOption: null,

            openGoalExpectedOutcomeForm:false,
            goalExpectedOutcomeOption: null,

            openPipelineForm:false,
            pipelineOption: null,

            openDurationForm: false,
            goalDurationOption: null,
            editableDuration: false,
            firstDay : null,
            lastDay : null,
            goalDurationStart :  null,
            goalDurationEnd :  null,
            popoverCurrentTarget : null,
        };
    }

    closeAllForm = () => {
        this.setState(this.getInitialState());
    }


    /* Start Goal Title*/
    openGoalTitleForm = (e,goalTitleOption) => {
        e.preventDefault();
        this.closeAllForm();
        this.setState({
            popoverCurrentTarget : e.currentTarget,
            openGoalTitleForm:true,
            goalTitleOption:goalTitleOption
        });
    }
    closeGoalTitleForm = (e) => {
        e.preventDefault();
        this.closeAllForm();
    }
    onChangeGoalTitle = (e) => {
        this.setState({
            goalTitleOption : e.target.value,
        });
    };
    saveGoalTitle = (e) => {
        e.preventDefault();
        if(this.state.goalTitleOption.trim() == '') {
            window.showNotification('error', 'The goal title is required');
            return;
        }
        if(this.state.goalTitleOption != this.props.goal.title){
            this.props.callBack({
                id:this.props.goal.id,
                title:this.state.goalTitleOption
            });
        }
        this.closeAllForm();
    }
    /* End Goal Title*/

    /* Start Goal Interval*/
    openGoalIntervalForm = (e,goalIntervalOption) => {
        e.preventDefault();
        this.closeAllForm();
        this.setState({
            popoverCurrentTarget : e.currentTarget,
            openGoalIntervalForm:true,
            goalIntervalOption:goalIntervalOption
        });
    }
    closeGoalIntervalForm = (e) => {
        e.preventDefault();
        this.closeAllForm();
    }
    onChangeGoalInterval = (e) => {
        this.setState({
            goalIntervalOption : e.target.value,
        });
    };
    saveGoalInterval = (e) => {
        e.preventDefault();
        if(this.state.goalIntervalOption != this.props.goal.goal_interval){
            if(parseInt(this.state.goalIntervalOption) > 0){
                this.props.callBack({
                    id:this.props.goal.id,
                    goal_interval:this.state.goalIntervalOption
                });
            } else {
                window.showNotification('error', 'Please select goal interval');
                return;
            }
        }
        this.closeAllForm();
    }
    /* End Goal Interval*/

    /* Start Goal Expected Outcome*/
    openGoalExpectedOutcomeForm = (e,goalExpectedOutcomeOption) => {
        e.preventDefault();
        this.closeAllForm();
        this.setState({
            popoverCurrentTarget : e.currentTarget,
            openGoalExpectedOutcomeForm:true,
            goalExpectedOutcomeOption:goalExpectedOutcomeOption
        });
    }
    closeGoalExpectedOutcomeForm = (e) => {
        e.preventDefault();
        this.closeAllForm();
    }
    onChangeGoalExpectedOutcome = (e) => {
        if(/^\d+$/.test(e.target.value)){
            this.setState({
                goalExpectedOutcomeOption : e.target.value,
            });
        }
        else{
            if(e.target.value.trim() == ''){
                this.setState({
                    goalExpectedOutcomeOption : '',
                });
            }
        }
        // if(parseInt(e.target.value) > -1){
        //     this.setState({
        //         goalExpectedOutcomeOption : e.target.value,
        //     });
        // }
    };
    saveGoalExpectedOutcome = (e) => {
        e.preventDefault();
        if(this.state.goalExpectedOutcomeOption.trim() == '' || parseInt(this.state.goalExpectedOutcomeOption) <= 0){
            // show validation message
        }
        else{
            if(this.state.goalExpectedOutcomeOption != this.props.goal.expected_outcome_value){
                this.props.callBack({
                    id:this.props.goal.id,
                    expected_outcome_value:this.state.goalExpectedOutcomeOption
                });
                this.closeAllForm();
            }
        }
    }
    /* End Goal Expected Outcome*/

    /* Start Pipeline*/
    pipelineList = () => {
        return this.props.pipelines.map((item,index)=> {
            return <MenuItem value={item.id} key={index}>{item.title}</MenuItem>
        })
    }
    openPipelineForm = (e,pipelineOption) => {
        e.preventDefault();
        this.closeAllForm();
        this.setState({
            openPipelineForm:true,
            pipelineOption:pipelineOption,
            popoverCurrentTarget : e.currentTarget
        });
    }
    closePipelineForm = (e) => {
        e.preventDefault();
        this.closeAllForm();
    }
    onChangePipeline = (e) => {
        this.setState({
            pipelineOption : e.target.value,
        });
    };
    savePipeline = (e) => {
        e.preventDefault();
        if(this.state.pipelineOption != this.props.goal.pipeline_id){
            if(parseInt(this.state.pipelineOption) > 0){
                this.props.callBack({
                    id:this.props.goal.id,
                    pipeline_id:this.state.pipelineOption
                });
            } else {
                window.showNotification('error', 'Please select a pipeline');
                return;
            }
        }
        this.closeAllForm();
    }
    /* End Pipeline*/

    /*Start duration form*/
    handleChange = date => {
        this.setState({
            firstDay: date,
            goalDurationStart : moment(date).format('DD/MM/YYYY'),
            goalDurationText :  moment(date).format('DD/MM/YYYY') +' - '+this.state.goalDurationEnd
        });
    };
    handleChangeDateEnd = (date) => {
        this.setState({
            lastDay: date,
            goalDurationEnd :  moment(date).format('DD/MM/YYYY'),
            goalDurationText : this.state.goalDurationStart +' - '+ moment(date).format('DD/MM/YYYY'),
        });
    };
    closeDurationForm = (e) => {
        e.preventDefault();
        this.closeAllForm();
    }
    openDurationForm = (e, goalDurationOption,goalDurationStart,goalDurationEnd) => {
        e.preventDefault();
        this.closeAllForm();
        this.setState({
            popoverCurrentTarget : e.currentTarget,
            openDurationForm:true,
            goalDurationStart:goalDurationStart,
            goalDurationEnd:goalDurationEnd,
            goalDurationOption:goalDurationOption,
            firstDay : new Date(date.getFullYear(), date.getMonth(), 1),
            lastDay : new Date(date.getFullYear(), date.getMonth() + 1, 0),
            editableDuration: goalDurationOption==Goal.GOAL_DURATION_TYPE_DATE_RANGE?true:false
        });
    }
    changeDurationOption = (e) => {
        if(parseInt(e.target.value) > 0) {

            let month = moment().format('M');
            let day   = moment().format('D');
            let year  = moment().format('YYYY');
            let quarter = moment().quarter();
            let goalDurationStart = moment().startOf('month').format('DD/MM/YYYY');
            let goalDurationEnd =  moment().endOf('month').format('DD/MM/YYYY');
            let startOfMonth = goalDurationStart;
            let endOfMonth = goalDurationEnd;
            let editableDuration = false;

            switch (parseInt(e.target.value)) {

                case Goal.GOAL_DURATION_TYPE_THIS_MONTH :
                    startOfMonth = moment().startOf('month').format('DD/MM/YYYY');
                    endOfMonth = moment().endOf('month').format('DD/MM/YYYY');
                    break;
                case Goal.GOAL_DURATION_TYPE_NEXT_MONTH :
                    startOfMonth = moment().add(1, 'months').startOf('month').format('DD/MM/YYYY');
                    endOfMonth = moment().add(1, 'months').endOf('month').format('DD/MM/YYYY');
                    break;
                case Goal.GOAL_DURATION_TYPE_THIS_QUARTER :
                    startOfMonth = moment().quarter(quarter).startOf('quarter').format('DD/MM/YYYY');
                    endOfMonth = moment().quarter(quarter).endOf('quarter').format('DD/MM/YYYY');
                    break;
                case Goal.GOAL_DURATION_TYPE_NEXT_QUARTER :
                    startOfMonth = moment().quarter(quarter + 1).startOf('quarter').format('DD/MM/YYYY');
                    endOfMonth = moment().quarter(quarter + 1).endOf('quarter').format('DD/MM/YYYY');
                    break;
                case Goal.GOAL_DURATION_TYPE_THIS_YEAR :
                    startOfMonth = moment().startOf('year').format('DD/MM/YYYY');
                    endOfMonth = moment().endOf('year').format('DD/MM/YYYY');
                    break;
                case Goal.GOAL_DURATION_TYPE_NEXT_YEAR :
                    startOfMonth = moment().add(1, 'years').startOf('year').format('DD/MM/YYYY');
                    endOfMonth = moment().add(1, 'years').endOf('year').format('DD/MM/YYYY');
                    break;
                case Goal.GOAL_DURATION_TYPE_DATE_RANGE :
                    startOfMonth = moment().startOf('month').format('DD/MM/YYYY');
                    endOfMonth   = moment().endOf('month').format('DD/MM/YYYY');
                    /*goalDurationStart = startOfMonth;
                    goalDurationEnd = endOfMonth;*/
                    editableDuration = true;
                    break;
                default:
                    break;

            }

            this.setState({
                goalDurationOption: parseInt(e.target.value),
                goalDurationStart: startOfMonth,
                goalDurationEnd: endOfMonth,
                editableDuration: editableDuration
            });
        }
    }
    saveDuration = (e) => {
        e.preventDefault();
        if(this.state.goalDurationOption != this.props.goal.goal_duration_type){
            this.props.callBack({
                id:this.props.goal.id,
                goal_duration_type:this.state.goalDurationOption,
                goal_duration_form: this.state.goalDurationStart,
                goal_duration_to: this.state.goalDurationEnd
            });
            this.closeAllForm();
        }
    }
    /* End duration*/

    componentDidMount() {

    }

    pipeline = (pipelineId) => {
        let value = this.props.pipelines.filter(pipeline => pipeline.id==pipelineId);
        if(value.length > 0)
            return value[0].title;
        else
            return '----';
    }

    getUser = (userId) => {
        let value = this.props.userList.filter(user => user.id==userId);
        if(value.length > 0)
            return value[0].full_name;
        else
            return '----';
    }

    goalType = (type) => {
        if(type == Goal.GOAL_TYPE_KEY_ACTIVITY_ADDED){
            return 'Activities added'
        } else if(type == Goal.GOAL_TYPE_KEY_ACTIVITY_COMPLETE){
            return 'Activities completed'
        } else if(type == Goal.GOAL_TYPE_KEY_DEALS_STARTED){
            return 'Deals started'
        } else if(type == Goal.GOAL_TYPE_KEY_DEALS_PROGRESSED){
            return 'Deals progressed'
        } else if(type == Goal.GOAL_TYPE_KEY_DEALS_WON){
            return 'Deals won'
        } else if(type == Goal.GOAL_TYPE_KEY_DEALS_LOST){
            return 'Deals lost'
        } else if(type == Goal.GOAL_TYPE_KEY_EMAIL_SENT){
            return 'Deals sent'
        } else if(type == Goal.GOAL_TYPE_KEY_EMAIL_RECEIVED){
            return 'Deals received'
        } else if(type == Goal.GOAL_TYPE_KEY_DEALS_VELOCITY){
            return 'Deal velocity'
        } else if(type == Goal.GOAL_TYPE_KEY_DEALS_CONVERSION){
            return 'Deals conversion'
        } else if(type == Goal.GOAL_TYPE_KEY_REVENUE_FORECAST){
            return 'Deals forecast'
        }
    }

    goalInterval = (interval) => {
        if(interval == Goal.GOAL_INTERVAL_WEEKLY){
            return 'Weekly'
        }else if(interval == Goal.GOAL_INTERVAL_MONTHLY){
            return 'Monthly'
        }else if(interval == Goal.GOAL_INTERVAL_QUARTERLY){
            return 'Quarterly'
        }else if(interval == Goal.GOAL_INTERVAL_YEARLY){
            return 'Yearly'
        }
    }

    expectedOutcome = (key,value) => {
        if(key == 1) {
            return value+' activities'
        }else{
            return '$ '+value
        }
    }

    deleteGoal = (e) => {
        e.preventDefault();
        this.props.deleteGoal({goalSettingId:this.props.goal.id});
    }

    render() {

        const {goal,indexRow} = this.props;
        return (
           <React.Fragment key={indexRow}>
            <div className="goal__activies__wr">
                <div className="goal__activies__top">
                    <h4 className="goal__activites__title">
                        <Tooltip arrow placement="top" title={goal.title}>
                            <span className="title"> {goal.title}</span>
                        </Tooltip>
                        <span className="goal__activites__subtitle">{/*"(Call)"*/}</span>
                        <span className="goal__activites__edit" onClick={e=>this.openGoalTitleForm(e,goal.title)}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3" stroke-opacity="0.1"/>
                            <path d="M12.7502 21.2963C12.3821 21.6652 11.9374 21.889 11.4237 21.9756C11.3934 21.9807 11.3642 21.9916 11.3342 21.9997C10.3959 21.9997 9.45754 21.9997 8.51919 21.9997C8.40382 21.9259 8.2742 21.8671 8.17781 21.774C8.09822 21.6973 8.05769 21.5797 8 21.48C8 20.5414 8 19.6029 8 18.6643C8.02556 18.554 8.04856 18.443 8.0774 18.3334C8.17891 17.9474 8.36767 17.6089 8.63786 17.3152C8.68094 17.2685 8.72621 17.2232 8.77112 17.1779C11.6245 14.3235 14.4793 11.4706 17.3302 8.61395C17.7223 8.22099 18.1787 7.99639 18.7391 8.00004C19.2251 8.00333 19.6527 8.16658 20.0003 8.50475C20.5063 8.99704 21.0083 9.49372 21.4972 10.0032C22.1453 10.6784 22.1665 11.7547 21.5611 12.4738C21.5085 12.5362 21.4516 12.5957 21.3935 12.6538C19.1368 14.9129 16.8792 17.1713 14.6225 19.4305L12.7502 21.2963ZM17.9333 9.99514C18.6208 10.6828 19.3175 11.3796 20.0163 12.079C20.0134 12.0812 20.0258 12.0742 20.0349 12.0647C20.1737 11.9267 20.3139 11.7897 20.4501 11.6491C20.6816 11.4099 20.6801 11.1276 20.4468 10.8873C20.304 10.7405 20.1573 10.597 20.0123 10.452C19.7063 10.146 19.4026 9.83774 19.0933 9.53535C18.894 9.34033 18.6198 9.31075 18.4266 9.48203C18.244 9.64418 18.086 9.83409 17.9333 9.99514Z" fill="#3C7EF3"/>
                            </svg>
                        </span>
                        <GlobalPopover
                                target={this.state.popoverCurrentTarget}
                                open={this.state.openGoalTitleForm}
                            >
                            <div className={"goal__edit-popover-form"}>
                                <input type="text" placeholder="Goal title" onChange={this.onChangeGoalTitle} value={this.state.goalTitleOption} name={'goalTitleOption'} className="chooseDuration form-control"/>

                                <div className="buttons">
                                    <Button color="secondary" variant="contained" onClick={e => this.closeGoalTitleForm(e)}>Cancel</Button>
                                    <Button color="primary" variant="contained" onClick={e => this.saveGoalTitle(e)}>Save</Button>
                                </div>
                            </div>
                        </GlobalPopover>
                    </h4>
                    <div className="goal__activites__top__right">
                        <Link to={'/goal/report/'+this.props.goal.id} title="Goal Report">
                            <span className="goal__activites__top__icon1">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3" stroke-opacity="0.1"/>
                            <path d="M8.49905 18.5C8.59756 18.5001 8.69513 18.4808 8.78614 18.4431C8.87715 18.4054 8.95982 18.3501 9.02939 18.2803L13.2491 14.0607L14.9687 15.7803C15.0384 15.85 15.121 15.9052 15.212 15.9429C15.303 15.9806 15.4006 16 15.4991 16C15.5975 16 15.6951 15.9806 15.7861 15.9429C15.8771 15.9052 15.9598 15.85 16.0294 15.7803L20.7488 11.061L20.7485 12.75C20.7485 12.9489 20.8275 13.1397 20.9682 13.2803C21.1088 13.421 21.2996 13.5 21.4985 13.5C21.6974 13.5 21.8882 13.421 22.0288 13.2803C22.1695 13.1397 22.2485 12.9489 22.2485 12.75L22.2491 9.25C22.2491 9.05109 22.17 8.86032 22.0294 8.71967C21.8887 8.57902 21.698 8.5 21.4991 8.5H17.9991C17.8001 8.5 17.6094 8.57902 17.4687 8.71967C17.3281 8.86032 17.2491 9.05109 17.2491 9.25C17.2491 9.44891 17.3281 9.63968 17.4687 9.78033C17.6094 9.92098 17.8001 10 17.9991 10H19.6884L15.4991 14.1893L13.7794 12.4697C13.7097 12.4 13.6271 12.3448 13.5361 12.3071C13.4451 12.2694 13.3475 12.25 13.2491 12.25C13.1506 12.25 13.053 12.2694 12.962 12.3071C12.871 12.3448 12.7884 12.4 12.7187 12.4697L7.96871 17.2197C7.86381 17.3245 7.79238 17.4582 7.76344 17.6037C7.7345 17.7492 7.74935 17.9 7.80612 18.037C7.86288 18.1741 7.95902 18.2912 8.08236 18.3736C8.2057 18.456 8.35071 18.5 8.49905 18.5Z" fill="#3C7EF3"/>
                            <path d="M21.5 20H8.5C8.30109 20 8.11032 20.079 7.96967 20.2197C7.82902 20.3603 7.75 20.5511 7.75 20.75C7.75 20.9489 7.82902 21.1397 7.96967 21.2803C8.11032 21.421 8.30109 21.5 8.5 21.5H21.5C21.6989 21.5 21.8897 21.421 22.0303 21.2803C22.171 21.1397 22.25 20.9489 22.25 20.75C22.25 20.5511 22.171 20.3603 22.0303 20.2197C21.8897 20.079 21.6989 20 21.5 20Z" fill="#3C7EF3"/>
                            </svg>
        
                            </span>

                        </Link>
                        <span className="goal__activites__top__icon2" onClick={e => this.deleteGoal(e)} title='Delete Goal'>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="15" cy="15" r="14.5" stroke="#FF5858" stroke-opacity="0.6"/>
                            <path d="M19.2644 10.5769H17.0962V10.1827C17.0962 9.5295 16.5667 9 15.9135 9H14.3365C13.6833 9 13.1538 9.5295 13.1538 10.1827V10.5769H10.9856C10.4413 10.5769 10 11.0182 10 11.5625V12.351C10 12.5687 10.1765 12.7452 10.3942 12.7452H19.8558C20.0735 12.7452 20.25 12.5687 20.25 12.351V11.5625C20.25 11.0182 19.8087 10.5769 19.2644 10.5769ZM13.9423 10.1827C13.9423 9.96537 14.1192 9.78846 14.3365 9.78846H15.9135C16.1308 9.78846 16.3077 9.96537 16.3077 10.1827V10.5769H13.9423V10.1827Z" fill="#FF264A"/>
                            <path d="M10.7482 13.5352C10.6778 13.5352 10.6218 13.5939 10.6251 13.6642L10.9504 20.4904C10.9804 21.1221 11.4993 21.6169 12.1316 21.6169H18.1185C18.7507 21.6169 19.2696 21.1221 19.2997 20.4904L19.6249 13.6642C19.6283 13.5939 19.5722 13.5352 19.5019 13.5352H10.7482ZM16.702 14.5207C16.702 14.3029 16.8784 14.1265 17.0962 14.1265C17.314 14.1265 17.4904 14.3029 17.4904 14.5207V19.6457C17.4904 19.8635 17.314 20.04 17.0962 20.04C16.8784 20.04 16.702 19.8635 16.702 19.6457V14.5207ZM14.7308 14.5207C14.7308 14.3029 14.9072 14.1265 15.125 14.1265C15.3429 14.1265 15.5193 14.3029 15.5193 14.5207V19.6457C15.5193 19.8635 15.3429 20.04 15.125 20.04C14.9072 20.04 14.7308 19.8635 14.7308 19.6457V14.5207ZM12.7597 14.5207C12.7597 14.3029 12.9361 14.1265 13.1539 14.1265C13.3717 14.1265 13.5481 14.3029 13.5481 14.5207V19.6457C13.5481 19.8635 13.3717 20.04 13.1539 20.04C12.9361 20.04 12.7597 19.8635 12.7597 19.6457V14.5207Z" fill="#FF264A"/>
                            </svg>
                        </span>
                    </div>
                </div>



               <div className={"goal__activies__content"} key={indexRow}>
                    <div className="goal__activites__list__single">
                        <div className="activites__name__label">
                        Assignee :
                        </div>
                        <div className="activites__name__content">
                            {this.props.assigneeName ? this.props.assigneeName : this.getUser(goal.assignee_to)}
                        </div>
                    </div>
                    <div className="goal__activites__list__single">
                        <div className="activites__name__label">
                        Goal type:
                        </div>
                        <div className="activites__name__content">
                        {this.goalType(goal.goal_type_key)}
                        </div>
                    </div>
                    <div className="goal__activites__list__single">
                        <div className="activites__name__label">
                        Pipeline:
                        </div>
                        <div className="activites__name__content">
                            {/* {this.pipeline(goal.pipeline_id)} */}
                            {(goal.assignee_type === AssigneeTypes.TYPE_USER && goal.assignee_to !== Utils.getAccountData('userId')) ? 
                                <>
                                    {(goal.goal_type_key == GoalTypes.GOAL_TYPE_KEY_ACTIVITY_ADDED || goal.goal_type_key == GoalTypes.GOAL_TYPE_KEY_ACTIVITY_COMPLETED) ? 'No Specific pipeline' : 'All pipelines' }
                                </>
                            :
                            this.pipeline(goal.pipeline_id)
                            }
                            {(goal.assignee_type !== AssigneeTypes.TYPE_USER || goal.assignee_to === Utils.getAccountData('userId')) &&
                            <span className="goal__activites__edit" onClick={e =>this.openPipelineForm(e,goal.pipeline_id)}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3" stroke-opacity="0.1"/>
                                <path d="M12.7502 21.2963C12.3821 21.6652 11.9374 21.889 11.4237 21.9756C11.3934 21.9807 11.3642 21.9916 11.3342 21.9997C10.3959 21.9997 9.45754 21.9997 8.51919 21.9997C8.40382 21.9259 8.2742 21.8671 8.17781 21.774C8.09822 21.6973 8.05769 21.5797 8 21.48C8 20.5414 8 19.6029 8 18.6643C8.02556 18.554 8.04856 18.443 8.0774 18.3334C8.17891 17.9474 8.36767 17.6089 8.63786 17.3152C8.68094 17.2685 8.72621 17.2232 8.77112 17.1779C11.6245 14.3235 14.4793 11.4706 17.3302 8.61395C17.7223 8.22099 18.1787 7.99639 18.7391 8.00004C19.2251 8.00333 19.6527 8.16658 20.0003 8.50475C20.5063 8.99704 21.0083 9.49372 21.4972 10.0032C22.1453 10.6784 22.1665 11.7547 21.5611 12.4738C21.5085 12.5362 21.4516 12.5957 21.3935 12.6538C19.1368 14.9129 16.8792 17.1713 14.6225 19.4305L12.7502 21.2963ZM17.9333 9.99514C18.6208 10.6828 19.3175 11.3796 20.0163 12.079C20.0134 12.0812 20.0258 12.0742 20.0349 12.0647C20.1737 11.9267 20.3139 11.7897 20.4501 11.6491C20.6816 11.4099 20.6801 11.1276 20.4468 10.8873C20.304 10.7405 20.1573 10.597 20.0123 10.452C19.7063 10.146 19.4026 9.83774 19.0933 9.53535C18.894 9.34033 18.6198 9.31075 18.4266 9.48203C18.244 9.64418 18.086 9.83409 17.9333 9.99514Z" fill="#3C7EF3"/>
                                </svg>
                            </span>
                            }
                            <GlobalPopover
                                target={this.state.popoverCurrentTarget}
                                open={this.state.openPipelineForm}
                            >
                                <div className={"goal__edit-popover-form"}>
                                    <Select onChange={this.onChangePipeline} defaultValue={this.state.pipelineOption} name={'pipelineOption'} id="chooseDuration" className="chooseDuration form-control">
                                        <option value="0">Select Pipeline</option>
                                        {this.pipelineList()}
                                    </Select>

                                    <div className="buttons">
                                        <Button color="secondary" variant="contained" onClick={e => this.closePipelineForm(e)}>Cancel</Button>
                                        <Button color="primary" variant="contained" onClick={e => this.savePipeline(e)}>Save</Button>
                                    </div>
                                </div>
                            </GlobalPopover>
                        </div>
                    </div>
                    <div className="goal__activites__list__single">
                        <div className="activites__name__label">
                        Interval:
                        </div>
                        <div className="activites__name__content">
                            {this.goalInterval(goal.goal_interval)}
                            <span className="goal__activites__edit" onClick={e=>this.openGoalIntervalForm(e,goal.goal_interval)}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3" stroke-opacity="0.1"/>
                                <path d="M12.7502 21.2963C12.3821 21.6652 11.9374 21.889 11.4237 21.9756C11.3934 21.9807 11.3642 21.9916 11.3342 21.9997C10.3959 21.9997 9.45754 21.9997 8.51919 21.9997C8.40382 21.9259 8.2742 21.8671 8.17781 21.774C8.09822 21.6973 8.05769 21.5797 8 21.48C8 20.5414 8 19.6029 8 18.6643C8.02556 18.554 8.04856 18.443 8.0774 18.3334C8.17891 17.9474 8.36767 17.6089 8.63786 17.3152C8.68094 17.2685 8.72621 17.2232 8.77112 17.1779C11.6245 14.3235 14.4793 11.4706 17.3302 8.61395C17.7223 8.22099 18.1787 7.99639 18.7391 8.00004C19.2251 8.00333 19.6527 8.16658 20.0003 8.50475C20.5063 8.99704 21.0083 9.49372 21.4972 10.0032C22.1453 10.6784 22.1665 11.7547 21.5611 12.4738C21.5085 12.5362 21.4516 12.5957 21.3935 12.6538C19.1368 14.9129 16.8792 17.1713 14.6225 19.4305L12.7502 21.2963ZM17.9333 9.99514C18.6208 10.6828 19.3175 11.3796 20.0163 12.079C20.0134 12.0812 20.0258 12.0742 20.0349 12.0647C20.1737 11.9267 20.3139 11.7897 20.4501 11.6491C20.6816 11.4099 20.6801 11.1276 20.4468 10.8873C20.304 10.7405 20.1573 10.597 20.0123 10.452C19.7063 10.146 19.4026 9.83774 19.0933 9.53535C18.894 9.34033 18.6198 9.31075 18.4266 9.48203C18.244 9.64418 18.086 9.83409 17.9333 9.99514Z" fill="#3C7EF3"/>
                                </svg>
                            </span>
                            <GlobalPopover
                                target={this.state.popoverCurrentTarget}
                                open={this.state.openGoalIntervalForm}
                            >
                                <div className={"goal__edit-popover-form"}>
                                    <Select onChange={this.onChangeGoalInterval} defaultValue={this.state.goalIntervalOption} name={'goalIntervalOption'} id="chooseDuration" className="chooseDuration form-control">
                                        <option value="0">Select Goal Interval</option>
                                        <MenuItem value={Goal.GOAL_INTERVAL_WEEKLY}>Weekly</MenuItem>
                                        <MenuItem value={Goal.GOAL_INTERVAL_MONTHLY}>Monthly</MenuItem>
                                        <MenuItem value={Goal.GOAL_INTERVAL_QUARTERLY}>Quarterly</MenuItem>
                                        <MenuItem value={Goal.GOAL_INTERVAL_YEARLY}>Yearly</MenuItem>
                                    </Select>

                                    <div className="buttons">
                                        <Button color="secondary" variant="contained" onClick={e => this.closeGoalIntervalForm(e)}>Cancel</Button>
                                        <Button color="primary" variant="contained" onClick={e => this.saveGoalInterval(e)}>Save</Button>
                                    </div>
                                </div>
                            </GlobalPopover>
                        </div>
                    </div>
                    <div className="goal__activites__list__single">
                        <div className="activites__name__label">
                        Expected outcome:
                        </div>
                        <div className="activites__name__content">
                            {this.expectedOutcome(goal.expected_outcome_key,goal.expected_outcome_value)}
                            <span className="goal__activites__edit" onClick={e=>this.openGoalExpectedOutcomeForm(e,goal.expected_outcome_value)}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3" stroke-opacity="0.1"/>
                                <path d="M12.7502 21.2963C12.3821 21.6652 11.9374 21.889 11.4237 21.9756C11.3934 21.9807 11.3642 21.9916 11.3342 21.9997C10.3959 21.9997 9.45754 21.9997 8.51919 21.9997C8.40382 21.9259 8.2742 21.8671 8.17781 21.774C8.09822 21.6973 8.05769 21.5797 8 21.48C8 20.5414 8 19.6029 8 18.6643C8.02556 18.554 8.04856 18.443 8.0774 18.3334C8.17891 17.9474 8.36767 17.6089 8.63786 17.3152C8.68094 17.2685 8.72621 17.2232 8.77112 17.1779C11.6245 14.3235 14.4793 11.4706 17.3302 8.61395C17.7223 8.22099 18.1787 7.99639 18.7391 8.00004C19.2251 8.00333 19.6527 8.16658 20.0003 8.50475C20.5063 8.99704 21.0083 9.49372 21.4972 10.0032C22.1453 10.6784 22.1665 11.7547 21.5611 12.4738C21.5085 12.5362 21.4516 12.5957 21.3935 12.6538C19.1368 14.9129 16.8792 17.1713 14.6225 19.4305L12.7502 21.2963ZM17.9333 9.99514C18.6208 10.6828 19.3175 11.3796 20.0163 12.079C20.0134 12.0812 20.0258 12.0742 20.0349 12.0647C20.1737 11.9267 20.3139 11.7897 20.4501 11.6491C20.6816 11.4099 20.6801 11.1276 20.4468 10.8873C20.304 10.7405 20.1573 10.597 20.0123 10.452C19.7063 10.146 19.4026 9.83774 19.0933 9.53535C18.894 9.34033 18.6198 9.31075 18.4266 9.48203C18.244 9.64418 18.086 9.83409 17.9333 9.99514Z" fill="#3C7EF3"/>
                                </svg>
                            </span>
                            <GlobalPopover
                                target={this.state.popoverCurrentTarget}
                                open={this.state.openGoalExpectedOutcomeForm}
                            >
                                <div className={"goal__edit-popover-form"}>
                                    <input type="number" value={this.state.goalExpectedOutcomeOption} className="chooseDuration form-control goalInputEdit" name={'goalExpectedOutcomeOption'} id="goalExpectedOutcomeOption" onChange={this.onChangeGoalExpectedOutcome}/>
                                    {( (this.state.goalExpectedOutcomeOption != null) && (this.state.goalExpectedOutcomeOption == '' || parseInt(this.state.goalExpectedOutcomeOption) <= 0)) &&
                                    <span className="deal_value_validation_text">No activities added !</span>
                                    }
                                    <div className="buttons">
                                        <Button color="secondary" variant="contained" onClick={e => this.closeGoalExpectedOutcomeForm(e)}>Cancel</Button>
                                        <Button color="primary" variant="contained" onClick={e => this.saveGoalExpectedOutcome(e)}>Save</Button>
                                    </div>
                                </div>
                            </GlobalPopover>
                        </div>
                    </div>
                    <div className="goal__activites__list__single">
                        <div className="activites__name__label">
                            Duration:
                        </div>
                        <div className="activites__name__content">
                            {moment(goal.goal_duration_form).format("MM/DD/Y")} - {moment(goal.goal_duration_to).format("MM/DD/Y")}
                            <span className="goal__activites__edit" onClick={e => this.openDurationForm(e,goal.goal_duration_type,moment(goal.goal_duration_form).format("MM/DD/Y"),moment(goal.goal_duration_to).format("MM/DD/Y"))}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3" stroke-opacity="0.1"/>
                                <path d="M12.7502 21.2963C12.3821 21.6652 11.9374 21.889 11.4237 21.9756C11.3934 21.9807 11.3642 21.9916 11.3342 21.9997C10.3959 21.9997 9.45754 21.9997 8.51919 21.9997C8.40382 21.9259 8.2742 21.8671 8.17781 21.774C8.09822 21.6973 8.05769 21.5797 8 21.48C8 20.5414 8 19.6029 8 18.6643C8.02556 18.554 8.04856 18.443 8.0774 18.3334C8.17891 17.9474 8.36767 17.6089 8.63786 17.3152C8.68094 17.2685 8.72621 17.2232 8.77112 17.1779C11.6245 14.3235 14.4793 11.4706 17.3302 8.61395C17.7223 8.22099 18.1787 7.99639 18.7391 8.00004C19.2251 8.00333 19.6527 8.16658 20.0003 8.50475C20.5063 8.99704 21.0083 9.49372 21.4972 10.0032C22.1453 10.6784 22.1665 11.7547 21.5611 12.4738C21.5085 12.5362 21.4516 12.5957 21.3935 12.6538C19.1368 14.9129 16.8792 17.1713 14.6225 19.4305L12.7502 21.2963ZM17.9333 9.99514C18.6208 10.6828 19.3175 11.3796 20.0163 12.079C20.0134 12.0812 20.0258 12.0742 20.0349 12.0647C20.1737 11.9267 20.3139 11.7897 20.4501 11.6491C20.6816 11.4099 20.6801 11.1276 20.4468 10.8873C20.304 10.7405 20.1573 10.597 20.0123 10.452C19.7063 10.146 19.4026 9.83774 19.0933 9.53535C18.894 9.34033 18.6198 9.31075 18.4266 9.48203C18.244 9.64418 18.086 9.83409 17.9333 9.99514Z" fill="#3C7EF3"/>
                                </svg>
                            </span>
                            <GlobalPopover
                                target={this.state.popoverCurrentTarget}
                                open={this.state.openDurationForm}
                            >
                                <div className={"goal__edit-popover-form"}>
                                    
                                    <Select  onChange={this.changeDurationOption} defaultValue={this.state.goalDurationOption} name={'goalDurationOption'} id="chooseDuration" className="chooseDuration form-control">
                                        <option value="0">Goal Duration</option>
                                        <MenuItem value={Goal.GOAL_DURATION_TYPE_THIS_MONTH}>This Month</MenuItem>
                                        <MenuItem value={Goal.GOAL_DURATION_TYPE_NEXT_MONTH}>Next Month</MenuItem>
                                        <MenuItem value={Goal.GOAL_DURATION_TYPE_THIS_QUARTER}>This quarter</MenuItem>
                                        <MenuItem value={Goal.GOAL_DURATION_TYPE_NEXT_QUARTER}>Next quarter</MenuItem>
                                        <MenuItem value={Goal.GOAL_DURATION_TYPE_THIS_YEAR}>This year</MenuItem>
                                        <MenuItem value={Goal.GOAL_DURATION_TYPE_NEXT_YEAR}>Next year</MenuItem>
                                        <MenuItem value={Goal.GOAL_DURATION_TYPE_DATE_RANGE}>Custom period</MenuItem>
                                    </Select>

                                    <If condition={this.state.editableDuration}>
                                            <div className=" select-control">
                                                <div className="dates-container">
                                                    <div className="input-dates">
                                                        <div className="input-date-field">
                                                            <DatePicker
                                                                selected={this.state.firstDay}
                                                                onChange={this.handleChange}
                                                                className="form-control"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dateFormatCalendar="MMMM"
                                                                yearDropdownItemNumber={2}
                                                                scrollableYearDropdown
                                                                dateFormat="dd/MM/yyyy"
                                                            />

                                                        </div>
                                                        <span> - </span>
                                                        <div className="input-date-field">
                                                            <DatePicker
                                                                selected={this.state.lastDay}
                                                                onChange={this.handleChangeDateEnd}
                                                                className="form-control"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dateFormatCalendar="MMMM"
                                                                yearDropdownItemNumber={2}
                                                                scrollableYearDropdown
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </If>

                                    <div className="buttons">
                                        <Button color="secondary" variant="contained" onClick={e => this.closeDurationForm(e)}>Cancel</Button>
                                        <Button color="primary" variant="contained" onClick={e => this.saveDuration(e)}>Save</Button>
                                    </div>
                                </div>
                            </GlobalPopover>
                        </div>
                    </div>
                </div>
            </div>
           </React.Fragment>
        );


    }

}
export default SingleGoal;