import React from 'react';
import DealProgressByuser from "../dealProgress/dealProgressByuser";
import DealProgressByUserOverTime from "../dealProgress/DealProgressByUserOverTime";
import UserReportTab from "../activityAddedChart/userReportTab";
import DealProgressByStage from '../dealProgress/DealProgressByStage';
import DealProgressByStageOverTime from '../dealProgress/DealProgressByStageOverTime';
import If from 'if-else-react';
import { Tab, Tabs } from '@material-ui/core';

class DealProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab:'by-stage'
        };
    };

    showTab=(tab)=>{
        this.setState({activeTab:tab});

    }

    render() {
        const {activeTab}=this.state;
        const {selectedDateType,selectedPipeline,selectedTeamUser,selectedDateFrom,selectedDateTo}=this.props;
        return (
            <React.Fragment>
                <div className="goalreport__right effort__report">
                    <div className="effort__top__wrapper">
                        <div className="goalreport__right__top effort__right__top">
                            <h2 className="goalreport__right__top_title">How are deals progressing?</h2>
                            <div className="">
                                 <Tabs variant="scrollable" className="effor__tab__topper" value={activeTab} onChange={(e,tabValue) => this.showTab(tabValue)}>
                                    <Tab className="effort__tab__button" value={'by-stage'} label="by Stages" />
                                    <Tab className="effort__tab__button" value={'by-stages-over-time'} label="by Stages over time" />
                                    <Tab className="effort__tab__button" value={'by-user'} label="by Users"/>
                                    <Tab className="effort__tab__button" value={'by-user-over-time'} label="by Users over time"  />
                                </Tabs>
                            </div>
                        </div>
                        <div className="goalreport__main__content effort__main__content">
                            <If condition={activeTab=='by-user'}>
                                <DealProgressByuser activeTab={activeTab}
                                                    selectedDateType={selectedDateType}
                                                    selectedPipeline={selectedPipeline}
                                                    selectedTeamUser={selectedTeamUser}
                                                    selectedDateFrom={selectedDateFrom}
                                                    selectedDateTo={selectedDateTo}
                                                    
                                />
                            </If>
                            <If condition={activeTab=='by-user-over-time'}>
                                <DealProgressByUserOverTime
                                    activeTab={activeTab}
                                    selectedDateType={selectedDateType}
                                    selectedPipeline={selectedPipeline}
                                    selectedTeamUser={selectedTeamUser}
                                    selectedDateFrom={selectedDateFrom}
                                    selectedDateTo={selectedDateTo}
                                />
                            </If>
                            <If condition={activeTab=='by-stage'}>
                                <DealProgressByStage activeTab={activeTab}
                                                    selectedDateType={selectedDateType}
                                                    selectedPipeline={selectedPipeline}
                                                    selectedTeamUser={selectedTeamUser}
                                                    selectedDateFrom={selectedDateFrom}
                                                    selectedDateTo={selectedDateTo}
                                                    goal={this.props.goal}
                                />
                            </If>
                            <If condition={activeTab=='by-stages-over-time'}>
                                <DealProgressByStageOverTime activeTab={activeTab}
                                                    selectedDateType={selectedDateType}
                                                    selectedPipeline={selectedPipeline}
                                                    selectedTeamUser={selectedTeamUser}
                                                    selectedDateFrom={selectedDateFrom}
                                                    selectedDateTo={selectedDateTo}
                                />
                            </If>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DealProgress
