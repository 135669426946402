import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";


const useStyles = makeStyles({
  titleText: {
    color: "#181f48",
    fontWeight: "600 !important",
    marginBottom: "0px !important",
  },
});

const GoalIndexSkeleton = () => {
  const { titleText } = useStyles();

  return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rectangular" width="80%" height={40} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rectangular" width="80%" height={40} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rectangular" width="80%" height={40} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rectangular" width="80%" height={40} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rectangular" width="80%" height={40} />
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "4px", padding: "0px 8px" }}>
          <Grid item xs={6}>
            <Skeleton variant="rectangular" width="80%" height={40} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={200} height={40} />
        </Grid>
    </Grid>
  );
};

export default GoalIndexSkeleton;
