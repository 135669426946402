import React, {useEffect} from "react";
import './App.css';
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import Utils from "./helpers/Utils";
import ErrorBoundary from "./components/common/ErrorBoundary";

const defaultHistory = createBrowserHistory();

function App({ history = defaultHistory }) {

    const generateClassName = createGenerateClassName({
        productionPrefix: 'rprt_' + Utils.generateRandomString(5),
    });

    return (
        <ErrorBoundary>
            <StylesProvider generateClassName={generateClassName}>
                <Router history={history}>
                    <Routes history={history} />
                </Router>
            </StylesProvider>
        </ErrorBoundary>
    );
}

export default App;
