import { FormControl, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { GoalTypes, OutcomeTypes } from "../../../constants/CoreConstants";
import { useIsMounted } from "../../../hooks/IsMounted";
import useDelayCallback from "../../../hooks/useDelayCallback";
import { iconList } from "../../globals/IconList";
import SelectComponent from "../SelectComponent";
import { BasicInput } from "../../common/BasicInput";

const StepFourth = props => {

    const isMounted = useIsMounted();
    const [expectedOutcomeValue,setExpectedOutcomeValue] = useState('');
    const [expectedOutcomeType,setExpectedOutcomeType] = useState(OutcomeTypes.EXPECTED_OUTCOME_AMOUNT);
    const [validStep,setValidStep] = useState(false);

    useEffect(() => {
        setValidStep(false);
        props.validateStep(4,false);
    },[expectedOutcomeValue])

    useDelayCallback(() => {
        if(isMounted) {
            let isValid = expectedOutcomeValue !== '';
            setValidStep(isValid);
            props.validateStep(4,isValid,{
                expectedOutcomeValue : expectedOutcomeValue,
                expectedOutcomeType : expectedOutcomeType
            });
        }
    },[expectedOutcomeValue])

    return ( 
        <>
        <div className={`add__goal_item ${props.stepComplete < 4 ? 'mb-2' : ''}`}>
            <div className="step__label__icon">
                { validStep ? iconList.checkMark : iconList.circle }
                </div>
                <div className="step__level__select__content__wr">
                <div className={`step__label__name ${props.stepActive == 4 ? 'assigne__label__name' : ''}`}>Expected outcome</div>
            </div>
        </div>

        {props.stepActive === 4 &&
            <div className="setp__label__left__border">
                <h4 className="step__label__name__title">What is the desired target for this goal?</h4>
                <div className="step__select__content">
                    <div>
                        <BasicInput
                        placeholder="Insert value" type="number" defaultValue={expectedOutcomeValue}
                         onChange={e => setExpectedOutcomeValue(e.target.value)} 
                        />
                    </div>
                        
                    {props.postData.selectedGoalType !==  GoalTypes.GOAL_TYPE_KEY_ACTIVITY_ADDED && props.postData.selectedGoalType !==  GoalTypes.GOAL_TYPE_KEY_ACTIVITY_COMPLETED &&
                        <div className="add__goal__select2">
                            <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                                <SelectComponent
                                    value={expectedOutcomeType}
                                    onChange={e => setExpectedOutcomeType(e.target.value)}>
                                    <MenuItem value={OutcomeTypes.EXPECTED_OUTCOME_AMOUNT}>Amount (deals)</MenuItem>
                                    <MenuItem value={OutcomeTypes.EXPECTED_OUTCOME_VALUE}>Value (USD)</MenuItem>
                                </SelectComponent>
                            </FormControl>
                        </div>
                    }
                </div>
            </div>
        }
        { props.stepComplete >= 4 && props.stepActive != 4 &&
            <div className="">
                <h4 className="active__border__green pro__lead__group">{expectedOutcomeValue}</h4>
            </div>
        }
        </>
    );
}
 
export default StepFourth;