import React, { useEffect, useState} from 'react';
import axis from 'axis.js';
import GlobalModal from '../globals/Modal/GlobalModal';
import { Add } from '@material-ui/icons';
import './addColumnModal.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ModalAddColumnForm = props => {

    const [isOpen,setIsOpen] = useState(false);
    const [defaultColumn,setDefaultColumn] = useState(props.defaultColumns);

    useEffect(() => {
        setIsOpen(props.isOpen);
    },[props.isOpen]);

    useEffect(() => {
        setDefaultColumn(props.defaultColumns);
    },[props.defaultColumns.lenght]);

    const handleSubmit = (e) => {
        e.preventDefault();
        closeModal();
        props.addData(defaultColumn);
    };

    const closeModal = () => {
        props.closeModal();
    };

    const handelRadio = (e) => {
        let previousColumn = Object.assign({},defaultColumn);

        if (e.target.checked) {
            if(axis.isUndefined(previousColumn[e.target.name]))
                previousColumn[e.target.name] = e.target.value;
            }
        else {
            if(!axis.isUndefined(previousColumn[e.target.name]))
                delete previousColumn[e.target.name];
        }

        setDefaultColumn(previousColumn);
    };

    const getRunningList = () => {
        let runningList = [];
        for (let i in props.allColumns){
            const isChecked = (!axis.isUndefined(defaultColumn[i])) ? true : false;
            runningList.push(<div key={i} className={"add__column__checkbox " +(isChecked == true? 'active__add__column__checkbox':'')}>
                <FormControlLabel
                control={
                    <Checkbox
                        checked={isChecked}
                        onChange={handelRadio}
                        name={i}
                        value={props.allColumns[i]}
                        color="primary"
                    />
                    }
                    label={props.allColumns[i]}
                />
            </div>)
        }

        return runningList;
    }

    return (

        <GlobalModal 
            open={props.isOpen} 
            onClose={() => closeModal()}
            onSubmit={handleSubmit}
            title={props.title}
            buttonText={'Generate Column'}
            buttonIcon={<Add/>}
            bodyClass={"modal__body__reportcolm awesome__scroll_bar3"}
            >
            <div className="row">
                <div className="trtr">
                    {getRunningList()}
                </div>
            </div>
        </GlobalModal>
    );
}

export default ModalAddColumnForm;
