import React from 'react';
import {connect} from "react-redux";
import FirstFilterColumnWise from './FirstFilterColumnWise'
import SecondFilterColumnWise from './SecondColumnWise'
import ReactEcharts from 'echarts-for-react';
import axis from 'axis.js';
import Moment from 'moment';

const mapStateToProps = state => {
    return {
        currentSecColumnFilterTitle: state.reportReducer.currentSecColumnFilterTitle,
        currentSecColumnFilterID: state.reportReducer.currentSecColumnFilterID,
        currentFirstColumnFilterTitle: state.reportReducer.currentFirstColumnFilterTitle,
        currentFirstColumnFilterID: state.reportReducer.currentFirstColumnFilterID,
        reportCurrentUser: state.reportReducer.reportCurrentUser
    };
};


class TopReportGraph extends React.Component{
    constructor(props){
        super(props);
    }

    getOption = () => {

        let calculate = {
            dates : [],
            firstValue : [],
            secondValue : [],

        };
        this.props.reportCurrentUser.map((data, index) =>{
            if(!axis.isUndefined(data[this.props.currentFirstColumnFilterID])){
                calculate.dates.push(Moment(data.created_at).format("ddd, MMM D YYYY"));
                calculate.firstValue.push(data[this.props.currentFirstColumnFilterID])
            }
        });

        this.props.reportCurrentUser.map((data, index) =>{
            if(!axis.isUndefined(data[this.props.currentSecColumnFilterID])){
                calculate.secondValue.push(data[this.props.currentSecColumnFilterID])
            }
        });

        return {
            tooltip : {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#252d47'
                    }
                }
            },
            toolbox: {
                feature: {
                    saveAsImage: {
                        title : "",
                    }
                }
            },
            legend: {
                data:[this.props.currentFirstColumnFilterTitle,this.props.currentSecColumnFilterTitle]
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: calculate.dates,
                nameTextStyle : {
                  color:'#ffffff'
                }
            },
            yAxis: {
                type: 'value',
                axisTick: {
                    show: false
                },
                splitLine: {
                    lineStyle: {
                        color: '#f7f7f7'
                    }
                }
            },
            series: [{
                name: this.props.currentFirstColumnFilterTitle,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#114ea9' // color at 0% position
                    }, {
                        offset: 1, color: '#3bd1bf' // color at 100% position
                    }],
                    global: false // false by default
                },
                data: calculate.firstValue,
                type: 'line',
                areaStyle: {
                    normal: {
                    }
                },
                smooth: true,
                showSymbol: true,
                showAllSymbol: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    normal: {
                        width: 2
                    }
                },
            },
                {
                    name: this.props.currentSecColumnFilterTitle,
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: '#ff3221' // color at 0% position
                        }, {
                            offset: 1, color: '#e88389' // color at 100% position
                        }],
                        global: false // false by default
                    },
                    data: calculate.secondValue,
                    type: 'line',
                    areaStyle: {
                        normal: {
                        }
                    },
                    smooth: true,
                    showSymbol: true,
                    showAllSymbol: true,
                    symbol: 'circle',
                    symbolSize: 10,
                    lineStyle: {
                        normal: {
                            width: 2
                        }
                    }
                }]
        }


    };


    render() {
        return (
            <>
                <div className="chart__select__wr">
                    <div className="chart__select__group">
                        <FirstFilterColumnWise/>
                        <SecondFilterColumnWise/>
                    </div>
                </div>

                <div className="business__report__content">
                    <ReactEcharts  style={{height: '250px', width: '100%'}}
                    className={"Echart__custom"}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    theme={"light"}
                                    option={this.getOption()} />
                </div>
            </>
        )
    }
}
const ReportGraph = connect(mapStateToProps, null)(TopReportGraph);

export default ReportGraph;
