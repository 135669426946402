import { FormControl, MenuItem } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { DurationTypes } from "../../../constants/CoreConstants";
import { iconList } from "../../globals/IconList";
import SelectComponent from "../SelectComponent";

const StepSixth = props => {

    const [goalDurationOption,setGoalDurationOption] = useState(0);
    const [durationText,setDurationText] = useState('');

    const handleDurationChange = (e,elem) => {
        setGoalDurationOption(e.target.value);

        let quarter = moment().quarter();
        let goalDurationStart = moment().startOf('month').format('DD/MM/YYYY');
        let goalDurationEnd =  moment().endOf('month').format('DD/MM/YYYY');

        switch (e.target.value) {
            case DurationTypes.GOAL_DURATION_TYPE_THIS_MONTH :
                goalDurationStart = moment().startOf('month').format('DD/MM/YYYY');
                goalDurationEnd   = moment().endOf('month').format('DD/MM/YYYY');
                break;
            case DurationTypes.GOAL_DURATION_TYPE_NEXT_MONTH :
                goalDurationStart = moment().add(1, 'months').startOf('month').format('DD/MM/YYYY');
                goalDurationEnd   = moment().add(1, 'months').endOf('month').format('DD/MM/YYYY');
                break;
            case DurationTypes.GOAL_DURATION_TYPE_THIS_WEEK :
                goalDurationStart = moment().startOf('week').format('DD/MM/YYYY');
                goalDurationEnd   = moment().endOf('week').format('DD/MM/YYYY');
                break;
            case DurationTypes.GOAL_DURATION_TYPE_NEXT_WEEK :
                goalDurationStart = moment().add(1, 'weeks').startOf('week').format('DD/MM/YYYY');
                goalDurationEnd   =moment().add(1, 'weeks').endOf('week').format('DD/MM/YYYY');
                break;
            case DurationTypes.GOAL_DURATION_TYPE_THIS_QUARTER :
                goalDurationStart = moment().quarter(quarter).startOf('quarter').format('DD/MM/YYYY');
                goalDurationEnd = moment().quarter(quarter).endOf('quarter').format('DD/MM/YYYY');
                break;
            case DurationTypes.GOAL_DURATION_TYPE_NEXT_QUARTER :
                goalDurationStart = moment().quarter(quarter + 1).startOf('quarter').format('DD/MM/YYYY');
                goalDurationEnd = moment().quarter(quarter + 1).endOf('quarter').format('DD/MM/YYYY');
                break;
            case DurationTypes.GOAL_DURATION_TYPE_THIS_YEAR :
                goalDurationStart = moment().startOf('year').format('DD/MM/YYYY');
                goalDurationEnd   = moment().endOf('year').format('DD/MM/YYYY');
                break;
            case DurationTypes.GOAL_DURATION_TYPE_NEXT_YEAR :
                goalDurationStart = moment().add(1, 'years').startOf('year').format('DD/MM/YYYY');
                goalDurationEnd   = moment().add(1, 'years').endOf('year').format('DD/MM/YYYY');
                break;
            case DurationTypes.GOAL_DURATION_TYPE_DATE_RANGE :
                goalDurationStart = moment().startOf('month').format('DD/MM/YYYY');
                goalDurationEnd   = moment().endOf('month').format('DD/MM/YYYY');
                break;
            default:
                break;
        }

        setDurationText(elem.props.children + " " + goalDurationStart + " - " + goalDurationEnd);

        props.validateStep(6,true,{
            goalDurationOption : goalDurationOption,
            goalDurationStart : goalDurationStart,
            goalDurationEnd : goalDurationEnd
        });
    }

    return ( 
        <>
        <div className={`add__goal_item ${props.stepComplete < 6 ? 'mb-2' : ''}`}>
            <div className="step__label__icon">
                { (props.stepComplete >= 6) ? iconList.checkMark : iconList.circle }
                </div>
                <div className="step__level__select__content__wr">
                <div className={`step__label__name ${props.stepActive == 6 ? 'assigne__label__name' : ''}`}>Goal duration</div>
                </div>
        </div>

        { props.stepActive == 6 &&
            <div className="setp__label__left__border">
                <h4 className="step__label__name__title">How long will this goal stay active?</h4>
                <div className="step__select__content">
                    <div className="add__goal__select1">
                        <FormControl variant="outlined" margin="none" focused="false" className="goal_select__wr">
                            <SelectComponent
                                value={goalDurationOption}
                                onChange={handleDurationChange}>
                                <MenuItem value="0" disabled>Goal Duration</MenuItem>
                                <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_THIS_MONTH}>This Month</MenuItem>
                                <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_NEXT_MONTH}>Next Month</MenuItem>
                                <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_THIS_WEEK}>This Week</MenuItem>
                                <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_NEXT_WEEK}>Next Week</MenuItem>
                                {/* <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_THIS_QUARTER}>This quarter</MenuItem> */}
                                {/* <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_NEXT_QUARTER}>Next quarter</MenuItem> */}
                                <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_THIS_YEAR}>This Year</MenuItem>
                                <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_NEXT_YEAR}>Next Year</MenuItem>
                                {/* <MenuItem value={DurationTypes.GOAL_DURATION_TYPE_DATE_RANGE}>Custom period</MenuItem> */}
                            </SelectComponent>
                        </FormControl>
                    </div>
                </div>
            </div>
        }
        {
            props.stepComplete >= 6 && props.stepActive !== 6 && 
            <div className="">
                <h4 className="active__border__green pro__lead__group">{durationText}</h4>
            </div>
        }
        </>
    );
}
 
export default StepSixth;